import React, { useEffect, useState } from "react";
import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import GenerateKeyForm from './AddForm';
import APIList from './ApiList';
import { ReactComponent as SpaceIcon } from 'containers/account/Space/assets/space.svg';

import { getAvatarApiKeys, generateApiKeyEffect, deleteApiKeyEffect } from 'store/neyra/effects';
import { MODES } from 'containers/account/Space/SpaceSidebar';

const MainContent = ({ setMode, selectedSpace }) => {
  const [apiKeys, setApiKeys] = useState([]);

  useEffect (() => {
    const init = async () => {
      try {
        const data = await getAvatarApiKeys();
        setApiKeys(data)
      } catch (error) {
        console.log(error)
      }
    }
    init()
  }, [])

  const generateApiKey = async (name) => {
    try {
      const newKey =  await generateApiKeyEffect({
        avatar_id: selectedSpace.id,
        name: name,
      });
      const data = await getAvatarApiKeys();
      setApiKeys(data.map((item) => {
        if (item.expiration === newKey.data.exp) {
          return { ...item, fullSecret: newKey.data.secret }
        }
        return item
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const onDelete = async (id) => {
    try {
      await deleteApiKeyEffect(id);
      setApiKeys((data) => (data.filter((item) => item.id !== id)))
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <>
      <SidebarItem item={{
        icon: <SpaceIcon />,
        isMain: true,
        name: 'API',
        action: () => {},
        backButton: true,
        backOnClick: () => {
          setMode(MODES.menu)
        }
      }}
      />
      <APIList
        list={apiKeys}
        onDelete={onDelete}
      />
      <GenerateKeyForm
        list={apiKeys}
        onCreate={generateApiKey}
      />
    </>
  )
}

export default MainContent;