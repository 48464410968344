
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import uuid from 'react-uuid';

import {
  createMessage,
  setConversationUid,
  setNeyraWrites,
  setBiosMessage,
  setShowChatModal,
} from 'features/neyra';
import {
  createConversation,
  sendMessage,
  getAvatarBySlug,
} from 'store/neyra/effects';

import getToken from 'utils/auth/get-token';
import { useMemoMessanger } from 'containers/pages/hook';
import useNotification from 'utils/hooks/use-notification';
import { canShowChatModal } from 'utils/url/is-page';
import CommandLineTerminal from '.';

let controller;

const CommandLineTerminalWrapper = ({
  generateAiHandler,
  handleInputChange,
  isStudioDrawTab,
}) => {
  const audiocontext = useRef();

  const {
    conversationUid,
    neyraWrites,
    chatModifiers,
    biosMessage,
    showChatModal,
  } = useSelector((state) => state.neyra);
  const { autoSpeaker } = useSelector((state) => state.speaker);
  const [ localAvatar, setLocalAvatar ] = useState({ avatarLoading: true, avatarId: 1 })
  const dispatch = useDispatch();
  const history = useHistory();
  const { sendMessageMemoProcess } = useMemoMessanger();

  const { addNotification } = useNotification();
  const { t } = useTranslation('owner');
  const isStudio = location.pathname === '/studio';
  const canDisplayChatModal = canShowChatModal()
  const isMemo = location.pathname === '/memos' ||
    location.pathname.includes('/memo/');
  const searchParams = new URLSearchParams(history.location.search);
  const searchAvatar = searchParams.get('avatar');

  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    audiocontext.current = new AudioContext();
  }, []);

  const sendMessageProcess = async (
    message,
    hideMyMessage,
    conversation_uid
  ) => {
    let spareConversationalUid = '';
    if (!conversation_uid) {
      spareConversationalUid = await checkUUID();
    }
    if (!showChatModal && canDisplayChatModal) {
      dispatch(setShowChatModal(true));
    }
    const nextMessage = `(talent) ${biosMessage}\n user: ${message}`;
    dispatch(setNeyraWrites(true));
    controller = new AbortController();
    const token = getToken();
    !hideMyMessage &&
      dispatch(
        createMessage({
          id: uuid(),
          text: message,
          voice: false,
          timeStamp: new Date(),
          isNeyro: false,
        })
      );

    const result = await sendMessage(
      token,
      conversation_uid || spareConversationalUid,
      biosMessage ? nextMessage : message,
      null,
      false,
      controller,
      autoSpeaker,
      null,
      localAvatar.avatarId,
      chatModifiers
    );

    if (biosMessage) {
      dispatch(setBiosMessage(''));
    }

    if (result?.status === 402) {
      addNotification(t('chat.notEnoughCredits'), 'alert');
      dispatch(setNeyraWrites(false));
      return;
    }

    if (result) {
      dispatch(setNeyraWrites(false));
    } else {
      dispatch(setNeyraWrites(false));
      addNotification('Error!', 'alert');
    }
  };

  const cancelRequest = () => {
    if (controller) {
      controller.abort();
      controller = null;
      dispatch(setNeyraWrites(false));
    }
  };

  useEffect(() => {
    const initiate = async () => {
      try {
        let nextAvatar 
        if (searchAvatar) {
          nextAvatar = await getAvatarBySlug(searchAvatar);
          setLocalAvatar({ avatarLoading: false, avatarId: nextAvatar.id })
        } else {
          nextAvatar = { id: 1 }
          setLocalAvatar({ avatarLoading: false, avatarId: 1 })
        }
      } catch (error) {
        console.warn(error);
      }
    };
    !conversationUid && initiate();
  }, [searchAvatar, conversationUid, dispatch]);

  const sendMessageHandler = (value) => {
    if (isMemo) {
      sendMessageMemoProcess(value);
    } else if (isStudio) {
      generateAiHandler(value);
    } else {
      sendMessageProcess(value, false, conversationUid);
    }
  };

  const checkUUID = async () => {
    const { conversation_uid } = await createConversation(localAvatar.avatarId);
    dispatch(setConversationUid(conversation_uid));
    return conversation_uid;
  };

  return (
    <CommandLineTerminal
      neyraWrites={neyraWrites}
      sendMessage={(value) => {
        sendMessageHandler(value);
      }}
      isStudio={isStudio}
      handleInputChange={handleInputChange}
      isStudioDrawTab={isStudioDrawTab}
      cancelRequest={cancelRequest}
    />
  );
};

export default CommandLineTerminalWrapper;
