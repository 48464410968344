// Refactoring №2
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-h5-audio-player/lib/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';
import { downloadFile } from 'gdgateway-client/lib/es5';
import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';
import { useSelector } from 'react-redux';
import { selectWorkspaceCurrent } from 'store/plan/reducers/workspace-plan.reducer';
import isDataprepUrl from 'gdgateway-client/lib/es5/utils/isDataprepUrl';

import { getDownloadOTT } from 'store/home/effects';
import GhostLoader from 'components/GhostLoader';
import { actionsType } from 'config/actions-options';

import s from './style.module.scss';

const VideoViewer = ({ file }) => {
  const { t: accountT } = useTranslation('account');
  const { t: authT } = useTranslation('auth');
  const workspace = useSelector(selectWorkspaceCurrent);

  const [ipfsVideo, setIpfsVideo] = useState();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const videoRef = useRef(null);

  const mainRequest = async (fileId) => {
    const {
      data: {
        user_tokens: { token: oneTimeToken },
        gateway,
      },
    } = await getDownloadOTT([{ slug: fileId }], actionsType.VIEWED);

    await sendFileViewStatistic(fileId);
    const url = `${gateway.url}/stream/${fileId}/${oneTimeToken}`;
    setIpfsVideo(url);
  };

  const previewFromSP = async ({ file }) => {
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([{ slug: file.slug }], actionsType.VIEWED);

    await sendFileViewStatistic(file.slug);
    const blob = await downloadFile({
      file,
      jwtOneTimeToken: jwt_ott,
      oneTimeToken,
      endpoint: gateway.url,
      isEncrypted: false,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[file.slug] || gateway.upload_chunk_size,
    });
    if (blob) {
      try {
        const realBlob = new Blob([blob]);
        const url = URL.createObjectURL(realBlob);
        setIpfsVideo(url);
      } catch (e) {
        setError(e);
        throw e;
      }
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      setIpfsVideo(null);
    }
    const isDataprep = isDataprepUrl(workspace.gateway.url);
    if (file.is_on_storage_provider || !isDataprep) {
      previewFromSP({ file });
    } else {
      mainRequest(file.slug);
    }
  }, [file.slug]);

  const onLoadStart = () => {
    setIsLoaded(false);
  };
  const onLoadEnd = () => {
    setIsLoaded(true);
  };

  const renderVideo = () => {
    if (error) {
      return <div className="error">{authT('common.internalServerError')}</div>;
    }

    if (!ipfsVideo) {
      return (
        <div className="loader">
          <GhostLoader
            texts={[
              accountT('convertModal.loading'),
              accountT('convertModal.pleaseWait'),
            ]}
          />
        </div>
      );
    }
    if (ipfsVideo) {
      return (
        <>
          <video
            ref={videoRef}
            controls
            muted={false}
            autoPlay
            onLoadStart={onLoadStart}
            onCanPlay={onLoadEnd}
            className={isLoaded ? s.videoVisible : s.videoInvisible}
          >
            <source src={ipfsVideo} type="video/mp4" />
            <track kind="captions" />
          </video>
          {!isLoaded && (
            <div className="loader">
              <GhostLoader
                texts={[
                  accountT('convertModal.loading'),
                  accountT('convertModal.pleaseWait'),
                ]}
              />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="file-view__video-container">
      <div className="file-content-image-container video-player">
        {renderVideo()}
      </div>
    </div>
  );
};

export default VideoViewer;
