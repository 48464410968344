// Libs
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';

import { saveBlob, downloadFile } from 'gdgateway-client/lib/es5';

// Utils
import { downloadFileData } from 'utils/file/library-callbacks';
import { getDecryptedKey } from 'utils/file/getDecryptedKey';
import { getKeyFromLS } from 'utils/file/getKeyFromLS';

import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { getFileCids } from 'store/home/effects/file/get-file-cid';
import { getEncryptedFileKey } from 'containers/main/Main/components/Sharing/utils/get-key';
import { handleShowEncryptionKeyModal } from 'store/home/actions/file/encrypt.action';

import {
  encryptionKeyError,
  changeEncryptionStatus,
} from 'store/home/actions/file/encryption-key.action';
import {
  addTokenDownload,
  cancelDownload,
} from 'store/home/actions/file/download-file.action';

import { ONE_MB, MAX_PREVIEW_SIZE } from 'config/file-action-types';
import { sendFileDownloadStatistic } from '../statistic/file-statistic-effect';
import { actionsType } from 'config/actions-options';

export default (file, afterCb, securitiesParams, activationKey, dispatch) => {
  const { handlers, callbacks } = downloadFileData;
  const size = Number((file.size / ONE_MB).toFixed(1));
  let cidData;

  const callback = ({ type, params }) => {
    if (handlers.includes(type)) {
      callbacks[type]({ ...params, dispatch });
    } else {
      console.error(`Handler "${type}" isn't provided`);
    }
  };
  return async () => {
    const controller = new AbortController();
    dispatch(addTokenDownload(controller));
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([{ slug: file.slug }], actionsType.DOWNLOADED);
    if (file.is_on_storage_provider && size >= MAX_PREVIEW_SIZE) {
      cidData = await getFileCids({ slug: file.slug });
    }
    if (file.is_clientside_encrypted) {
      let decryptedKey;

      if (!activationKey) {
        const decryptedKeyFromLS = getKeyFromLS({ slug: file.slug });
        if (!decryptedKeyFromLS) {
          const key = await getEncryptedFileKey(file, dispatch);
          decryptedKey = await getDecryptedKey({ key });
        } else {
          decryptedKey = decryptedKeyFromLS;
        }
      } else {
        decryptedKey = activationKey;
      }

      if (decryptedKey) {
        try {
          await sendFileDownloadStatistic(file.slug);
          const blob = await downloadFile({
            file,
            oneTimeToken,
            jwtOneTimeToken: jwt_ott,
            endpoint: gateway.url,
            isEncrypted: true,
            key: decryptedKey,
            callback,
            handlers,
            signal: controller.signal,
            carReader: CarReader,
            uploadChunkSize:
              upload_chunk_size[file.slug] || gateway.upload_chunk_size,
            cidData,
          }).catch((error) => {
            let message = '';
            if (
              error?.message?.includes('HTTP') ||
              (error instanceof TypeError &&
                error.message.includes('Failed to fetch'))
            ) {
              message = 'Failed to fetch. Please, try again.';
            } else if (
              error instanceof DOMException ||
              error?.message === 'AES key data must be 128 or 256 bits' ||
              error?.message ===
                "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
            ) {
              message = 'Invalid key';
            } else {
              message = 'Something went wrong';
            }
            dispatch(changeEncryptionStatus(false));
            dispatch(
              encryptionKeyError({
                error: true,
                message,
              })
            );
          });
          if (blob && !blob?.failed) {
            const realBlob = new Blob([blob]);
            saveBlob({ blob: realBlob, name: file?.name, mime: file?.mime });
          } else {
            afterCb && afterCb(file);
            dispatch(changeEncryptionStatus(false));
            dispatch(
              encryptionKeyError({
                error: true,
                message: 'Invalid key',
              })
            );
          }
        } catch (e) {
          dispatch(
            encryptionKeyError({
              error: true,
              message: e.message || 'Something went wrong',
            })
          );
          dispatch(changeEncryptionStatus(false));
        }
      } else {
        dispatch(handleShowEncryptionKeyModal(file));
      }
      dispatch(changeEncryptionStatus(false));
      afterCb && afterCb(file);
      return;
    }

    await sendFileDownloadStatistic(file.slug);
    const blob = await downloadFile({
      file,
      oneTimeToken,
      jwtOneTimeToken: jwt_ott,
      endpoint: gateway.url,
      isEncrypted: false,
      callback,
      handlers,
      signal: controller.signal,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[file.slug] || gateway.upload_chunk_size,
      cidData,
    });

    if (blob && !blob?.failed) {
      const realBlob = new Blob([blob]);
      saveBlob({ blob: realBlob, name: file?.name, mime: file?.mime });
      afterCb && afterCb(file);
      return;
    } else {
      dispatch(changeEncryptionStatus(false));
      dispatch(
        encryptionKeyError({
          error: true,
          message: 'Invalid key',
        })
      );
    }
  };
};

// Do not use this request
export const downloadMixFiles =
  // eslint-disable-next-line no-unused-vars
  (downloadFiles, afterCb, securitiesParams, addToken) => async () => {
    const files = downloadFiles.map((file) => file.slug);
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
      },
    } = await getDownloadOTT(
      files.map((slug) => {
        return {
          slug,
        };
      })
    );

    const url = `${gateway.url}/download/mix`;

    const source = axios.CancelToken.source();
    addToken(source);

    axios
      .create({
        headers: {
          'one-time-token': oneTimeToken,
          'X-Download-OTT-JWT': jwt_ott,
        },
        cancelToken: source.token,
      })
      .post(url, files, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'download.zip');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        afterCb();
      })
      .catch(() => {
        afterCb();
      });
  };

export const cancelDownloadMixFiles = () => {
  return (dispatch, getState) => {
    const checkCancelTokenSource = () => {
      const { cancelTokenSource } = getState().home.fileDownload;

      if (cancelTokenSource == null) {
        setTimeout(checkCancelTokenSource, 300);
      } else {
        if ('signal' in cancelTokenSource) {
          cancelTokenSource.abort();
        } else {
          cancelTokenSource.cancel('Download canceled');
        }
      }
    };

    checkCancelTokenSource();
    dispatch(cancelDownload());
  };
};
