import { BN } from 'ethereumjs-util';
import axios from 'axios';
import { ContactDetailsByURi } from '../../../../../../../../share-by-link-nft';
import { getCollectionContract } from 'store/web3';

interface GetContractInfo {
  contract_address: string;
  slug: string;
}

export type ContractInfo = ContactDetailsByURi & {
  tokenId: string | number;
  royalty: string;
};

const DEFAULT_PRICE = 10000;

export const getContractInfo = async ({
  contract_address,
  slug,
}: GetContractInfo): Promise<ContractInfo> => {
  const contract = await getCollectionContract(contract_address);
  const tokenId: BN = await contract?.fileToToken(slug);
  const tokenIdNumber: number = tokenId.toNumber();
  const metadataUri: string = await contract.uri(tokenIdNumber);
  const normalizedUri: string = metadataUri.replace(
    '{id}',
    String(tokenIdNumber)
  );

  await new Promise((resolve) => setTimeout(resolve, 3000));
  const uri = normalizedUri.includes('filenft-testnet')
    ? normalizedUri.replace('testnet', 'tt')
    : normalizedUri;

  const response = await axios.get(uri);
  const validData = response as { data: ContactDetailsByURi };
  const [, royalty] = await contract.royaltyInfo(tokenIdNumber, DEFAULT_PRICE);
  return { ...validData.data, tokenId: tokenIdNumber, royalty };
};
