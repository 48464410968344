/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import CN from 'classnames';
import { useHistory } from "react-router";

import Header from "containers/mobile/components/header";
import { ReactComponent as ProfileIcon } from 'containers/mobile/assets/profile.svg';
import { ReactComponent as ChatIcon } from 'containers/mobile/assets/chat.svg';
import { ReactComponent as DriveIcon } from 'containers/mobile/assets/drive.svg';
import { ReactComponent as PlusIcon } from 'containers/mobile/assets/plus.svg';

import styles from './styles.module.scss';
import { getUserInfoClear } from 'store/home/effects/user-info/get-user-info-effect';
import { getNeyraAvatar } from 'store/neyra/effects';
import { NEIRA_AI_API_BASE } from 'constants/api-urls';

const Connect = ({ avatar, setAvatar }) => {
  const history = useHistory()
  const ref = useRef(null);

  useEffect(async () => {
    if (!avatar) {
      const user = await getUserInfoClear();
      const avatar = await getNeyraAvatar();
      const avatarItem = avatar?.length ? { ...avatar[0].profile, tokens: user.tokens } : { tokens: user.tokens }
      setAvatar(avatarItem)
    }
  }, [avatar])

  const handleLogoClick = () => {
    history.push('/profile')
  }

  const onIconChange = () => {
    ref.current.click()
  }

  const info = [
    {
      name: 'Wallet',
      icon:  <PlusIcon />,
      value: avatar?.tokens,
      action: () => { history.push('/wallet/buy-credits')},
    },
    {
      name: 'Referral',
      value: 'Share Link',
    },
  ]

  const products = [
    {
      name: 'chat',
      icon: <ChatIcon width={19} height={18} />,
      onClick: () => { history.push('/chat') }
    },
    {
      name: 'drive',
      icon: <DriveIcon width={27} height={18} />,
      onClick: () => { history.push('/drive')},
    },
  
  ]

  return (
    <div className={styles.connectWrapper}>
      <Header onLogoClick={handleLogoClick}  />
      <div className={styles.content}>
        <ProfileIcon />
        <div onClick={onIconChange} className={styles.logo}>
          { avatar?.image && (
            <img className={styles.logoImage} src={`${NEIRA_AI_API_BASE}${avatar.image}`} alt="Logo" />
          )}
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.products}>
          { products.map(({ name, icon, onClick }) => (
            <button onClick={onClick} className={CN(styles.productsItem, )} key={name}>
              {icon}
            </button>
          )) }
        </div>
        <div className={styles.info}>
          { info.map(({ name, icon, value, action }) => (
            <div
              className={CN(styles.infoItem, icon && styles.infoItemActive)}
              key={`info$-${name.toLowerCase()}`}
              onClick={() => {action && action()}}
            >
              <div className={styles.infoDetail}>
                <p  className={styles.infoItemLabel}>{name}</p>
                <span className={styles.infoItemValue}>{value}</span>
              </div>
              <span className={styles.infoAction}>
                { icon }
              </span>
            </div>
          )) }
        </div>
      </footer>
    </div>
  )
}

export default Connect;