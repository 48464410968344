/* eslint-disable no-debugger */
// Refactoring №3
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { getTokenCostEffect } from 'store/tokenCost/effects';
import { setFileView } from 'store/home/actions';
import UserContext from 'store/home/contexts/user-context';
import {
  addPromtToHistory,
  generateSkybox360Effect,
  generateAiEffectFromStableDiffusion,
  tokenUtilization,
} from 'store/aiGenerator/effects';
import { getFilesEffect, uploadMultiFileEffectRedux } from 'store/home/effects';
import { changeUserInfo } from 'store/account/actions';

import {
  handleNotEnoughTokenModal,
  selectNeyraPromptHelperModal,
} from 'features/modals/modal-slice';
import {
  createStudioFolder,
  getStudioFolders,
  selectIsGenerationCompleted,
  selectIsGenerationInProgress,
  selectStudioFolders,
  setGenerationPrice,
  setIsGenerationCompleted,
  setIsGenerationInProgress,
  setIsImageUploading,
} from 'features/studio';
import {
  extractTokensAction,
  selectSorterState,
  selectTokenCountState,
} from 'features/app';
import { isMobile } from 'utils/mobile';
import { TOKEN_COST } from 'config/token-cost';
import pusher from './utils/pusher_instance';

import NeyraBorder from 'components/NeyraBorder';
import NeyraSmallLogo from 'components/NeyraSmallLogo';
import { NeyraPromptHelper } from './NeyraPromptHelper';
import AiGeneratorSidebar from './generatorSidebar/ai-generator-sidebar';
import { AiGeneratorsErrorModal } from 'containers/header/header-container/components/ImageToImageModal/AiGeneratorsErrorModal';
import NotEnoughTokenModal from 'components/NotEnoughTokenModal';
import NeyraLogo from 'containers/auth/NeyraWelcome/component/NeyraLogo';
import { DrawTab } from './DrawTab';
import { Panorama360 } from './Panorama360';

import { ReactComponent as FullscreenIcon } from './assets/fullscreen.svg';
import { ReactComponent as DownloadIcon } from './assets/download.svg';

import CN from 'classnames';
import styles from './style.module.scss';

const {
  realtimeImageGenerator1,
  realtimeImageGenerator2,
  realtimeImageGenerator3,
  realtimeImageGenerator4,
} = TOKEN_COST;

export const aspectRatioSizes: {
  [key: number]: {
    [key: number]: {
      width: number;
      height: number;
      aspectRatio: string;
      index: number;
      priceKey?: string;
    };
  };
} = {
  512: {
    1: {
      width: 896,
      height: 512,
      aspectRatio: '7:4',
      index: 1,
      priceKey: realtimeImageGenerator2,
    },
    2: {
      width: 768,
      height: 512,
      aspectRatio: '3:2',
      index: 2,
      priceKey: realtimeImageGenerator2,
    },
    3: {
      width: 704,
      height: 512,
      aspectRatio: '4:3',
      index: 3,
      priceKey: realtimeImageGenerator1,
    },
    4: {
      width: 640,
      height: 512,
      aspectRatio: '5:4',
      index: 4,
      priceKey: realtimeImageGenerator1,
    },
    5: {
      width: 512,
      height: 512,
      aspectRatio: '1:1',
      index: 5,
      priceKey: realtimeImageGenerator1,
    },
    6: {
      width: 512,
      height: 640,
      aspectRatio: '4:5',
      index: 6,
      priceKey: realtimeImageGenerator1,
    },
    7: {
      width: 512,
      height: 704,
      aspectRatio: '3:4',
      index: 7,
      priceKey: realtimeImageGenerator1,
    },
    8: {
      width: 512,
      height: 768,
      aspectRatio: '2:3',
      index: 8,
      priceKey: realtimeImageGenerator2,
    },
    9: {
      width: 512,
      height: 896,
      aspectRatio: '4:7',
      index: 9,
      priceKey: realtimeImageGenerator2,
    },
  },
  1024: {
    1: {
      width: 1024,
      height: 640,
      aspectRatio: '8:5',
      index: 1,
      priceKey: realtimeImageGenerator3,
    },
    2: {
      width: 1024,
      height: 704,
      aspectRatio: '16:11',
      index: 2,
      priceKey: realtimeImageGenerator3,
    },
    3: {
      width: 1024,
      height: 768,
      aspectRatio: '4:3',
      index: 3,
      priceKey: realtimeImageGenerator3,
    },
    4: {
      width: 1024,
      height: 896,
      aspectRatio: '8:7',
      index: 4,
      priceKey: realtimeImageGenerator4,
    },
    5: {
      width: 1024,
      height: 1024,
      aspectRatio: '1:1',
      index: 5,
      priceKey: realtimeImageGenerator4,
    },
    6: {
      width: 896,
      height: 1024,
      aspectRatio: '7:8',
      index: 6,
      priceKey: realtimeImageGenerator4,
    },
    7: {
      width: 768,
      height: 1024,
      aspectRatio: '3:4',
      index: 7,
      priceKey: realtimeImageGenerator3,
    },
    8: {
      width: 704,
      height: 1024,
      aspectRatio: '11:16',
      index: 8,
      priceKey: realtimeImageGenerator3,
    },
    9: {
      width: 640,
      height: 1024,
      aspectRatio: '5:8',
      index: 9,
      priceKey: realtimeImageGenerator3,
    },
  },
  1280: {
    1: { width: 640, height: 1280, aspectRatio: '1:2', index: 1 },
    2: { width: 768, height: 1280, aspectRatio: '3:5', index: 2 },
    3: { width: 960, height: 1280, aspectRatio: '3:4', index: 3 },
    4: { width: 1024, height: 1280, aspectRatio: '4:5', index: 4 },
    5: { width: 1280, height: 1280, aspectRatio: '1:1', index: 5 },
    6: { width: 1280, height: 1024, aspectRatio: '5:4', index: 6 },
    7: { width: 1280, height: 960, aspectRatio: '4:3', index: 7 },
    8: { width: 1280, height: 768, aspectRatio: '5:3', index: 8 },
    9: { width: 1280, height: 640, aspectRatio: '2:1', index: 9 },
  },

  1536: {
    1: { width: 768, height: 1536, aspectRatio: '1:2', index: 1 },
    2: { width: 960, height: 1536, aspectRatio: '5:8', index: 2 },
    3: { width: 1024, height: 1536, aspectRatio: '2:3', index: 3 },
    4: { width: 1280, height: 1536, aspectRatio: '5:6', index: 4 },
    5: { width: 1536, height: 1536, aspectRatio: '1:1', index: 5 },
    6: { width: 1536, height: 1280, aspectRatio: '6:5', index: 6 },
    7: { width: 1536, height: 1024, aspectRatio: '3:2', index: 7 },
    8: { width: 1536, height: 960, aspectRatio: '8:5', index: 8 },
    9: { width: 1536, height: 768, aspectRatio: '2:1', index: 9 },
  },
};

export const imageSizeOptions = [512, 1024, 1280, 1536];

type aiGeneratorPropType = {
  aiServiceActive: string;
  setAiServiceActive: (servise: string) => void;
  match: any;
  fileViewType: string;
  setFileView: (arg: string) => void;
  userBalance: number;
  notEnoughToken: boolean;
  handleNotEnoughTokenModal: (arg: boolean) => void;
  aiSidebarMenu: boolean;
  isNeyraPromtHelperOpen: boolean;
  isGenerationInProgress: boolean;
  setIsGenerationInProgress: (arg: boolean) => void;
  setIsImageUploading: (arg: boolean) => void;
  setIsGenerationCompleted: (arg: boolean) => void;
  isGenerationCompleted: boolean;
};

const AiGenerator = ({
  userBalance,
  notEnoughToken,
  handleNotEnoughTokenModal,
  aiSidebarMenu,
  isNeyraPromtHelperOpen,
  setIsGenerationInProgress,
  isGenerationInProgress,
  setIsImageUploading,
  setIsGenerationCompleted,
  isGenerationCompleted,
}: aiGeneratorPropType) => {
  const audiocontext = useRef();
  const { t } = useTranslation('contextMenu');
  const { t: ownerT } = useTranslation('owner');
  const [error, setError] = useState({ status: false, message: '' });
  const [imageCount, setImageCount] = useState(1);
  const [mainImage, setMainImage] = useState('');
  const [generatedImage, setGeneratedImage] = useState<string[]>([]);
  const [imageSize, setImageSize] = useState(imageSizeOptions[0]);
  const [prompt, setPrompt] = useState('');
  const [secretPrompt, setSecretPromt] = useState('');
  const [notification, setNotification] = useState({
    text: '',
  });
  const [imageFilter, setImageFilter] = useState('');
  const [creativityStrength, setCreativityStrength] = useState(0.7);
  const [imageAspectRatio, setImageAspectRatio] = useState(
    aspectRatioSizes[imageSizeOptions[0]][5]
  );
  const [generatedImagePrice, setGeneratedImagePrice] = useState(0);
  const [skybox360Price, setSkybox360Price] = useState(0);
  const [realtimeImagesPrice, setRealtimeImagesPrice] = useState({});
  const [uploadedImagesCount, setUploadedImagesCount] = useState(0);
  const [skybox360Data, setSkybox360Data] = useState({});
  const [activeTab, setActiveTab] = useState('draw');
  const [is360UploadingInProgress, setIs360UploadingInProgress] =
    useState(false);
  const isDrawTab = activeTab === 'draw';
  const isSkyboxTab = activeTab === 'skybox';
  const isGenearateTab = activeTab === 'generate';
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const sorter = useSelector(selectSorterState);
  const { drawFolder, generationFolder, panoramaFolder } =
    useSelector(selectStudioFolders);

  const totalPrice = (generatedImagePrice * imageCount).toFixed(1);

  const filteredGeneratedImages = useMemo(
    () => generatedImage.filter((image) => image !== mainImage),
    [generatedImage, mainImage]
  );

  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    audiocontext.current = new AudioContext();

    getFilesEffect(null, { order_by: sorter.option, order: sorter.dir }).then(
      ({ data }) => {
        const files = data ? data : [];
        dispatch(getStudioFolders(files));
      }
    );
  }, []);

  useEffect(() => {
    if (isSkyboxTab) {
      dispatch(setGenerationPrice(skybox360Price));
    } else {
      dispatch(setGenerationPrice(totalPrice));
    }
  }, [totalPrice, isSkyboxTab]);

  const handleInputChange = (text: string) => {
    setPrompt(text);
  };

  useEffect(() => {
    getTokenCostEffect().then((data) => {
      const generatedImageCost = data.reduce(
        (_, item: { type: string; tokens: number }) => {
          if (TOKEN_COST.AIImages === item.type) {
            return item.tokens;
          }
          return _;
        },
        0
      );
      const generated360ImageCost = data.reduce(
        (acc: number, item: { type: string; tokens: number }) => {
          if (TOKEN_COST['360ImageGenerator'] === item.type) {
            return item.tokens;
          }
          return acc;
        },
        0
      );
      const realtimeImageCost = data.reduce(
        (
          acc: { [key: string]: number },
          item: { type: string; tokens: number }
        ) => {
          if (
            TOKEN_COST.realtimeImageGenerator1 === item.type ||
            TOKEN_COST.realtimeImageGenerator2 === item.type ||
            TOKEN_COST.realtimeImageGenerator3 === item.type ||
            TOKEN_COST.realtimeImageGenerator4 === item.type
          ) {
            return { ...acc, [item.type]: item.tokens };
          }
          return acc;
        },
        {}
      );
      setSkybox360Price(generated360ImageCost);
      setRealtimeImagesPrice(realtimeImageCost);
      setGeneratedImagePrice(generatedImageCost);
    });
  }, []);

  const isMobileDevice = isMobile && window.innerWidth <= 480;

  const cancelHandler = () => {
    if (error.status) {
      setError({ status: false, message: '' });
      setGeneratedImage([]);
    }
  };

  const getCurrentImageProportion = (proportion: string) => {
    const replacedString = proportion.replace(':', '/');
    setImageSize(replacedString);
  };

  function base64ToBlob(base64String: string, contentType: string) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  const convertPngToWebp = async (base64Png: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Png.startsWith('data')
        ? base64Png
        : `data:image/png;base64,${base64Png}`;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const base64Webp = canvas.toDataURL('image/webp');
        resolve(base64Webp);
      };

      img.onerror = () => {
        reject(new Error('Failed to load the image.'));
      };
    });
  };

  const getFolder = async () => {
    const folder = isDrawTab
      ? drawFolder
      : isSkyboxTab
      ? panoramaFolder
      : generationFolder;
    if (!folder) {
      const folderID = await dispatch(createStudioFolder(activeTab));
      return folderID;
    }
    return folder;
  };

  const processImage = async (base64Image: string, fileName: string) => {
    const convertedBase64 = await convertPngToWebp(base64Image).then(
      (base64Webp) => base64Webp
    );
    const newBlob = await fetch(convertedBase64).then((res) => res.blob());
    const webpFile = new File([newBlob], `${fileName}.webp`, {
      type: 'image/webp',
      lastModified: Date.now(),
    });
    return webpFile;
  };

  const saveImageHandler = async (
    generatedImageAsBase64: [string],
    fileName: string
  ) => {
    setIsImageUploading(true);
    const folderId = await getFolder();
    const files = [];
    for (const file of generatedImageAsBase64) {
      const convertedImage = await processImage(file, fileName);
      convertedImage.folderId = folderId;
      files.push(convertedImage);
    }

    dispatch(
      uploadMultiFileEffectRedux({
        folderData: {},
        files,
        folderId,
        setErrors: false,
        isGeneratedByAi: true,
        afterCb: () => setUploadedImagesCount((prev) => prev + 1),
      })
    );
  };

  const saveSkybox = async () => {
    const image = skybox360Data?.file_url;
    if (!image || is360UploadingInProgress) return;
    setIs360UploadingInProgress(true);
    const prompt = skybox360Data?.prompt;
    const folderId = await getFolder();
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], `${prompt}.jpg`, {
      type: 'image/jpg',
    });
    file.mime = 'hdri';
    file.folderId = folderId;
    const files = [file];
    dispatch(
      uploadMultiFileEffectRedux({
        folderData: {},
        files,
        folderId,
        setErrors: false,
        isGeneratedByAi: true,
        afterCb: () => setIs360UploadingInProgress(false),
      })
    );
  };

  const generateSkybox360 = async (prompt: string) => {
    if (Number(userBalance) < skybox360Price) {
      handleNotEnoughTokenModal(true);
      setIsGenerationInProgress(false);
      return;
    }
    setSkybox360Data({});
    setIsGenerationInProgress(true);
    try {
      const data = await generateSkybox360Effect(prompt, imageFilter);
      const channel = pusher.subscribe(data.pusher_channel);
      channel.bind(data.pusher_event, function (res) {
        if (res.status === 'complete') {
          tokenUtilization(Number(TOKEN_COST['360ImageGenerator']))
            .then(() => {
              dispatch(extractTokensAction(skybox360Price));
              setSkybox360Data(res);
              pusher.unsubscribe(data.pusher_channel);
              setIsGenerationInProgress(false);
            })
            .catch((err) => {
              setError({ status: true, message: err.message });
            });
        }
        if (res.status === 'error' || res.status === 'abort') {
          setIsGenerationInProgress(false);
          pusher.unsubscribe(data.pusher_channel);
          setError({ status: true, message: '' });
        }
      });
    } catch (error) {
      setError({ status: true, message: '' });
      setIsGenerationInProgress(false);
    }
  };

  const generateAiHandler = async (typedPrompt: string) => {
    if (isDrawTab || typedPrompt.trim() === '') return;
    if (isSkyboxTab) {
      await generateSkybox360(typedPrompt);
      return;
    }
    if (userBalance < totalPrice) {
      handleNotEnoughTokenModal(true);
      setIsGenerationInProgress(false);
      return;
    }
    setIsGenerationCompleted(false);
    setIsGenerationInProgress(true);
    setPrompt('');

    addPromtToHistory(typedPrompt.split('\n').join(', '));

    const composedPrompt = `Create a visually striking, high-quality, and uniquely stylish image based on the following prompt: ${typedPrompt
      .split('\n')
      .join(', ')}. ${secretPrompt ?? ''}`;
    const filter =
      imageFilter === 'neyra-face' ||
      imageFilter === 'neyra-art' ||
      imageFilter === 'neyra-punk'
        ? ''
        : imageFilter;
    const generationMethod = generateAiEffectFromStableDiffusion(
      composedPrompt,
      imageAspectRatio.width,
      imageAspectRatio.height,
      imageCount,
      filter
    );
    generationMethod
      .then((data: { imageUrls: [string]; base64Urls: [string] }) => {
        setGeneratedImage(data.imageUrls);
        setMainImage(data.imageUrls[0]);
        saveImageHandler(data.base64Urls, typedPrompt);

        tokenUtilization(1, imageCount)
          .then((data) => {
            dispatch(
              changeUserInfo({ ...user, userBalance: data.user.userBalance })
            );
            dispatch(extractTokensAction(totalPrice));
          })
          .catch(() => {
            setIsGenerationInProgress(false);
            setError({ status: true, message: '' });
            setGeneratedImage([]);
            setMainImage('');
          });
        setIsGenerationCompleted(true);
      })
      .catch((err) => {
        const errorText =
          err?.message === 'invalid promt' ||
          err?.message.includes('Invalid promt')
            ? t('main.imageToImage.invalidPromt')
            : err?.message;
        setIsGenerationInProgress(false);
        setError({ status: true, message: errorText });
      });
  };

  useEffect(() => {
    if (uploadedImagesCount === imageCount) {
      setIsImageUploading(false);
      setNotification({ text: ownerT('studio.imageSaved') });
      const timeoutId = setTimeout(() => {
        setNotification({ text: '' });
        setUploadedImagesCount(0);
      }, 6000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [uploadedImagesCount, imageCount]);

  const onLogoClick = () => history.push('/');

  const handleImageDownload = async () => {
    const convertedBase64 = await convertPngToWebp(mainImage).then(
      (base64Webp) => base64Webp
    );
    const link = document.createElement('a');
    link.href = convertedBase64 as string;
    link.download = prompt || 'image.webp';
    link.click();
  };

  const setGeneratedPrompt = (prompt: string) => {
    setPrompt(prompt);
  };

  const handleActiveTab = (e) => {
    const { id } = e.target;
    if (!id) return;
    setImageFilter('');
    if (id === 'draw') {
      setImageSize(imageSizeOptions[0]);
      setImageAspectRatio(aspectRatioSizes[imageSizeOptions[0]][5]);
    } else {
      setImageSize(imageSizeOptions[3]);
      setImageAspectRatio(aspectRatioSizes[imageSizeOptions[3]][8]);
    }
    setActiveTab(id);
  };

  const welcomeScreen = (
    <div className={styles.welcomeWrapper}>
      <div className={styles.welcomeWrapper__logo}>
        <NeyraLogo className={styles.neyraLogo} />
      </div>
      <h2 className={styles.welcomeWrapper__title}>{ownerT('studio.title')}</h2>
      <p className={styles.welcomeWrapper__desc}>
        {ownerT('studio.welcomeText')}
      </p>
      <p className={styles.welcomeWrapper__price}>{`Image generation: ${
        isSkyboxTab ? skybox360Price : totalPrice
      } credits`}</p>
    </div>
  );

  const renderSkybox360Tab = () => {
    switch (skybox360Data?.status) {
      case 'complete':
        return (
          <>
            <Panorama360 imageUrl={skybox360Data.file_url} />
            <div className={CN(styles.imageOptions, styles.downloadSkybox)}>
              <div className={styles.imageOptions__option} onClick={saveSkybox}>
                {is360UploadingInProgress ? (
                  <div className={styles.loader} />
                ) : (
                  <DownloadIcon />
                )}
              </div>
            </div>
          </>
        );
      default:
        return welcomeScreen;
    }
  };

  return (
    <>
      <section
        className={`${styles.wrapper} ${
          !aiSidebarMenu && !isMobileDevice ? styles.wrapper_margin : ''
        }`}
      >
        <NeyraBorder
          containerClass={styles.borderContainer}
          notification={notification}
          contentClass={isDrawTab ? styles.borderContent : ''}
          showCircles={!isDrawTab}
          handleInputChange={handleInputChange}
          generateAiHandler={generateAiHandler}
          isStudioDrawTab={isDrawTab}
        >
          {!isDrawTab && (
            <NeyraSmallLogo
              className={styles.NeyraLogo}
              handleClick={onLogoClick}
            />
          )}
          {isDrawTab && (
            <DrawTab
              mainImage={mainImage}
              imageFilter={imageFilter}
              imageAspectRatio={imageAspectRatio}
              creativityStrength={creativityStrength}
              realtimeImagesPrice={realtimeImagesPrice}
              prompt={prompt}
              getFolder={getFolder}
            />
          )}
          {isSkyboxTab && renderSkybox360Tab()}
          {isGenearateTab && (
            <div className={styles.imageWrapper}>
              {mainImage && !isGenerationInProgress ? (
                <div className={styles.generatedImageWrapper}>
                  <img
                    src={mainImage}
                    alt="generated by AI"
                    className={styles.mainPicture}
                  />
                </div>
              ) : (
                welcomeScreen
              )}
              {generatedImage.length > 1 && !isGenerationInProgress && (
                <div className={styles.smallImagesBlock}>
                  {filteredGeneratedImages.map((image) => (
                    <div
                      key={image}
                      className={styles.smallPictureWrapper}
                      onClick={() => setMainImage(image)}
                    >
                      <img
                        src={image}
                        alt="generated by AI"
                        className={styles.smallPicture}
                      />
                      <div>
                        <FullscreenIcon />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {mainImage && !isGenerationInProgress && (
                <div className={styles.imageOptions}>
                  <div
                    className={styles.imageOptions__option}
                    onClick={handleImageDownload}
                  >
                    <DownloadIcon />
                  </div>
                </div>
              )}
            </div>
          )}
        </NeyraBorder>
        {aiSidebarMenu && (
          <AiGeneratorSidebar
            setImageFilter={setImageFilter}
            setImageAspectRatio={setImageAspectRatio}
            setImageSize={setImageSize}
            imageSize={imageSize}
            imageFilter={imageFilter}
            setImageCount={setImageCount}
            imageCount={imageCount}
            setSecretPromt={setSecretPromt}
            isGeneratingCompleted={isGenerationCompleted}
            isDrawTab={isDrawTab}
            is3DTab={isSkyboxTab}
            isGenerateTab={isGenearateTab}
            imageAspectRatio={imageAspectRatio}
            setCreativityStrength={setCreativityStrength}
            creativityStrength={creativityStrength}
            handleActiveTab={handleActiveTab}
          />
        )}
      </section>
      <AiGeneratorsErrorModal
        isOpen={error.status}
        error={error.message}
        onClose={cancelHandler}
      />
      {isNeyraPromtHelperOpen && (
        <NeyraPromptHelper
          isNeyraPromtHelperOpen={isNeyraPromtHelperOpen}
          prompt={prompt}
          generateAiHandler={generateAiHandler}
          setGeneratedPrompt={setGeneratedPrompt}
        />
      )}
      {notEnoughToken && <NotEnoughTokenModal />}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  fileViewType: state.home?.fileView?.type,
  userBalance: selectTokenCountState(state),
  notEnoughToken: state.modals.notEnoughToken,
  aiSidebarMenu: state.leftMenu?.aiSidebarMenu,
  isNeyraPromtHelperOpen: selectNeyraPromptHelperModal(state),
  isGenerationInProgress: selectIsGenerationInProgress(state),
  isGenerationCompleted: selectIsGenerationCompleted(state),
});

const mapDispatchToProps = {
  setFileView,
  handleNotEnoughTokenModal,
  setIsGenerationInProgress,
  setIsImageUploading,
  setIsGenerationCompleted,
};
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AiGenerator
);
