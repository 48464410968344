import React, { useEffect } from "react";

import Modal from "react-modal";
import { getCityList } from 'store/neyra/effects';


import styles from './styles.module.scss';

const CityModal = ({ city, onCitySelect, list, setList, startCitySelctor }) => {

  useEffect(() => {
    if (city) {
      getCityList(city)
      .then((data) => {
        setList(data?.data || [])
      })
    }
  }, [city])

  const handleCitySelect = (city, land, sdiv, tz) => {
    onCitySelect(city, land, sdiv, tz);
  } 

  
  return (
    <Modal
      isOpen
      overlayClassName={(startCitySelctor && city && list.length )? styles.modalOverlay : styles.modalClose }
      className={styles.modalContent}
    >
      <div className={styles.cityList}>
        { list.map((item, index) => (
          <div
            className={styles.cityItem}
            onClick={() => {handleCitySelect(item.city, item.land, item.sdiv, item.tz)}}
            key={`index-${index}`}
          >
            <p className={styles.cityText}>{`${item.city} / ${item.land} / ${item.sdiv} / ${item.tz}`}</p>
          </div>
        )) }
      </div>
    </Modal>
  )
}

export default CityModal;