import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import classNames from 'classnames';

import { ReactComponent as MemoIcon } from './assets/memo.svg';
import { ReactComponent as MemosIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/memo.svg';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { ReactComponent as DeleteIcon } from 'components/VaultModal/assets/delete.svg';
import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';
import actionsOptions from 'config/actions-options';

import {
  getFilesSuccess,
  getFilesClear,
} from 'store/home/actions/files/get-files.actions';
import { getFilteredFilesEffect } from 'store/home/effects';
import { setConversationUid, clearConversationHistory } from 'features/neyra';
import { createConversation } from 'store/neyra/effects'
import { selectedFilesFoldersClear } from 'store/home/actions/selected-files-folders.actions';

import styles from './styles.module.scss';

const MemosSidebar = ({
  collapsed,
  files,
  filesCount,
  sorter,
  filter,
  getFilteredFilesEffect,
  getFilesSuccess,
  getFilesClear,
  setConversationUid,
  conversationHistory,
  clearConversationHistory,
  selectedFilesFoldersClear,
  fileFolderActionHandlerEffect,
}) => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getFilesClear();
    setIsLoading(true);
    setPage(1);
    getFilteredFilesEffect('memos', {
      page: 1,
      order_by: sorter.option,
      order: sorter.dir,
      color: filter.color,
    })
      .then((data) => {
        getFilesSuccess({ data }, true);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
    return () => {
      getFilesClear();
      selectedFilesFoldersClear();
    };
  }, [sorter, filter]);

  const getNextPage = () => {
    if (page * 15 < filesCount) {
      const nextPage = page + 1;
      setIsLoading(true);
      setPage(nextPage);
      getFilteredFilesEffect('memos', {
        page: nextPage,
        order_by: sorter.option,
        order: sorter.dir,
        color: filter.color,
      })
        .then((data) => {
          getFilesSuccess({ data });
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const hasMore = () => files.length < filesCount && !isLoading;

  const handleChangeMemo = (file) => {
    if (!isEmpty(conversationHistory)) {
      createConversation()
        .then(({ conversation_uid }) => {
          setConversationUid(conversation_uid);
          clearConversationHistory();
          history.push(
            `/memo/${file.slug}?filename=${file?.name}&filesize=${file?.size}`
          );
        })
    } else {
      history.push(
        `/memo/${file.slug}?filename=${file?.name}&filesize=${file?.size}`
      );
    }
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.goBack();
  };

  const deleteMemo = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    const slug = history.location.pathname.replace('/memo/', '')
    if (slug === file.slug) {
      history.push('/memos');
      fileFolderActionHandlerEffect(file, actionsOptions.remove);
    } else {
      fileFolderActionHandlerEffect(file, actionsOptions.remove);
    }
  }

  const sortedFiles = useMemo(() => {
    return files.sort((a, b) => b.created_at - a.created_at);
  }, [files]);

  return (
    <div
      className={classNames(
        styles.sidebarContainer,
        collapsed && styles.collapsed
      )}
    >
    <SidebarItem
      item={
        {
          name: 'Memories',
          icon: <MemosIcon />,
          url: '',
          action: () => { history.push('/memos') },
          backButton: true,
          backOnClick: handleGoBack,
          isMain: true,
          tooltipText: 'Back',
        }
      }
    />
      {sortedFiles.length ? (
        <InfiniteScroll
          className={styles.scrollContainer}
          initialLoad
          pageStart={2}
          threshold={250}
          loadMore={getNextPage}
          hasMore={hasMore()}
          useWindow={false}
          getScrollParent={() =>
            document.getElementsByClassName('entry-list ')[0]
          }
        >
          {sortedFiles.map((item) => (
             <div
               className={classNames(styles.sidebarItem, styles.button)}
               onClick={() => {
                 handleChangeMemo(item);
               }}
               key={`${item.id}-memo`}
             >
              <div className={styles.sidebarPreview}>
                <MemoIcon />
                {!collapsed && <span className={styles.title}>{item?.name}</span>}
              </div>
              <NeyraTooltip text='Back' position="bottom">
                <button
                  className={styles.sidebarAction}
                  onClick={(e) => {deleteMemo(e, item)}}
                >
                <DeleteIcon />
                </button>
              </NeyraTooltip>
             </div>
          ))}
        </InfiniteScroll>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = {
  getFilteredFilesEffect,
  getFilesSuccess,
  getFilesClear,
  selectedFilesFoldersClear,
  setConversationUid,
  clearConversationHistory,
  fileFolderActionHandlerEffect,
};

const mapStateToProps = (state) => ({
  files: state.home?.getFiles?.entities,
  filesCount: state.home?.getFiles?.count,
  conversationHistory: state.neyra.conversationHistory,
  avatar: state.neyra.avatar,
  sorter: state.app.sorter,
  filter: state.app.filter,
});

export default connect(mapStateToProps, mapDispatchToProps)(MemosSidebar);
