import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LIGHT_THEME, ThemeContext } from 'store/context/theme';
import {
  generateImageFromCanvasEffect,
  tokenUtilization,
} from 'store/aiGenerator/effects';
import { uploadMultiFileEffectRedux } from 'store/home/effects';
import { extractTokensAction, selectTokenCountState } from 'features/app';
import { handleNotEnoughTokenModal } from 'features/modals/modal-slice';

import NeyraLogo from 'containers/auth/NeyraWelcome/component/NeyraLogo';
import { SettingsPanel } from './settingsPanel';
import { ReactComponent as SwapIcon } from './assets/swap.svg';

import style from './style.module.scss';

type DrawAreaPropTypes = {
  mainImage: string;
  imageFilter: string;
  imageAspectRatio: any;
  creativityStrength: number;
  realtimeImagesPrice: { [key: string]: number };
  prompt: string;
  getFolder: () => Promise<string>;
};

export const DrawTab = ({
  mainImage,
  imageFilter,
  imageAspectRatio,
  creativityStrength,
  realtimeImagesPrice,
  prompt,
  getFolder,
}: DrawAreaPropTypes) => {
  const [drawingCommands, setDrawingCommands] = useState([]);
  const [color, setColor] = useState('#C900E0');
  const [brushSize, setBrushSize] = useState(10);
  const [generatedImage, setGeneratedImage] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(mainImage);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const isDarkTheme = theme !== LIGHT_THEME;
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const previousPositionRef = useRef({ x: 0, y: 0 });
  const generationCost = realtimeImagesPrice[imageAspectRatio.priceKey] ?? 2;
  const userBalance = useSelector(selectTokenCountState);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctxRef.current = ctx;
    const parent = canvas.parentElement;
    canvas.width = parent.clientWidth;
    canvas.height = parent.clientHeight;

    if (backgroundImage) {
      const canvasBackground = new Image();
      canvasBackground.src = backgroundImage;
      canvasBackground.onload = async () => {
        await ctx.drawImage(
          canvasBackground,
          0,
          0,
          canvas.width,
          canvas.height
        );
      };
    } else {
      ctx.fillStyle = isDarkTheme ? '#000' : '#dedede';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  }, [backgroundImage]);

  const handleMouseDown = (event) => {
    setIsDrawing(true);
    const { offsetX, offsetY } = event.nativeEvent;
    previousPositionRef.current = { x: offsetX, y: offsetY };
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = event.nativeEvent;
    const ctx = ctxRef.current;
    const { x: startX, y: startY } = previousPositionRef.current;
    ctx.imageSmoothingEnabled = false;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.strokeStyle = color;
    ctx.lineWidth = brushSize;
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
    previousPositionRef.current = { x: offsetX, y: offsetY };
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    setDrawingCommands((prevCommands) => [
      ...prevCommands,
      ctx.getImageData(0, 0, canvas.width, canvas.height),
    ]);
    const image = canvas.toDataURL('image/jpeg');
    generateImage(image);
  };

  const undo = () => {
    if (drawingCommands.length > 0) {
      setDrawingCommands((prevCommands) => prevCommands.slice(0, -1));
      const canvas = canvasRef.current;
      const ctx = ctxRef.current;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawingCommands.slice(0, -1).forEach((command) => {
        ctx.putImageData(command, 0, 0);
      });
    }
  };

  const clearCanvas = () => {
    setDrawingCommands([]);
    setGeneratedImage('');
    const canvas = canvasRef.current;
    const ctx = ctxRef.current;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = isDarkTheme ? '#000' : '#dedede';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  const generateImage = async (image: string) => {
    if (userBalance < generationCost) {
      dispatch(handleNotEnoughTokenModal(true));
      return;
    }
    await generateImageFromCanvasEffect(
      image,
      prompt,
      imageFilter,
      imageAspectRatio.width,
      imageAspectRatio.height,
      creativityStrength
    ).then((img) => {
      setGeneratedImage(img);
      tokenUtilization(imageAspectRatio.priceKey).then(() => {
        dispatch(extractTokensAction(generationCost));
      });
    });
  };

  const saveImageToDrive = async () => {
    setIsUploading(true);
    const folderId = await getFolder();
    const blob = await fetch(generatedImage).then((res) => res.blob());
    const webpFile = new File([blob], `${'painting'}.webp`, {
      type: 'image/webp',
      lastModified: Date.now(),
    });
    webpFile.folderId = folderId;
    dispatch(
      uploadMultiFileEffectRedux({
        folderData: {},
        files: [webpFile],
        folderId,
        setErrors: false,
        isGeneratedByAi: true,
        afterCb: () => setIsUploading(false),
      })
    );
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = generatedImage as string;
    link.download = 'painting.webp';
    link.click();
  };

  const swapOutputToInput = () => {
    setBackgroundImage(generatedImage);
  };

  return (
    <div className={style.drawWrapper}>
      <div className={style.canvasContent}>
        <div className={style.canvasContent__canvas}>
          <canvas
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          />
        </div>
        <div className={style.canvasContent__image}>
          {generatedImage ? (
            <img src={generatedImage} alt="Drawing" />
          ) : (
            <NeyraLogo className={style.neyraLogo} />
          )}
        </div>
        <button onClick={swapOutputToInput} className={style.swapButton}>
          <SwapIcon />
        </button>
      </div>

      <div className={style.drawSettings}>
        <SettingsPanel
          undo={undo}
          clearCanvas={clearCanvas}
          setColor={setColor}
          color={color}
          setBrushSize={setBrushSize}
          brushSize={brushSize}
        />
        <div className={style.drawSettings__buttons}>
          <p className={style.drawSettings__price}>
            Generation cost: <span>{generationCost} credits</span>
          </p>
          <button
            onClick={saveImageToDrive}
            className={style.driveBtn}
            disabled={!generatedImage.length}
          >
            {isUploading ? <div className={style.loader} /> : 'Save to Drive'}
          </button>
          <button onClick={downloadImage} disabled={!generatedImage.length}>
            Download
          </button>
        </div>
      </div>
    </div>
  );
};
