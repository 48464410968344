/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-debugger */
import React, { useState } from "react";

import ImagePreview from '../../components/ImagePreview';
import AudioStream from 'containers/file/audio-stream';
import VideoViewer from 'containers/file/video-viewer';
import DefaultContent from 'containers/file/default-content';
import PDFContent from 'containers/file/pdf-content';
import ExcelViewer from 'containers/file/excel-viewer';
import TxtViewer from 'containers/file/txt-viewer';
import { getPreviewFileType } from '../../utils';

import styles from './styles.module.scss';

const PreviewContent = ({ entityContent, entity }: any) => {
  const [previewFileType, setPreviewFileType] = useState(getPreviewFileType(entity, ' '));

  switch (previewFileType) {
    // case 'encrypt':
    //   return !imageMediaTypesPreview.includes(file.mime) ||
    //     imagesWithoutPreview.includes(`.${file.extension}`) ||
    //     !entityContent ? (
    //     <DefaultContent
    //       file={file}
    //       fileContentRef={fileContentRef}
    //       text={t('files.unavailableContent')}
    //       isEncrypt
    //     />
    //   ) : (
    //     <ImageContent
    //       file={file}
    //       entityContent={entityContent}
    //       fileContentRef={fileContentRef}
    //       isSvg={isSvg}
    //       footerContent={footerContent}
    //     />
    //   );
    case 'img':
      return (
        <ImagePreview
          file={entity}
          entityContent={entityContent}
        />
      );

    case 'pdf':
    case 'document':
      return (
        <PDFContent
          entity={entity}
          setEntityType={setPreviewFileType}
          className={styles.pdfPreview}
          rootClassName={styles.pdfPreviewRoot}
        />
      );

    case 'audio':
      return <AudioStream file={entity} />;

    case 'video':
      return (
        <div className={styles.videoContainer}>
          <VideoViewer file={entity} />;
        </div>
      )
      
    case 'xlsx':
      return <ExcelViewer file={entity} />;
    case 'txt':
      return <TxtViewer file={entity} />;

    default:
      return (
        <DefaultContent
          file={entity}
          text={''}
        />
      );
  }

}

export default PreviewContent;