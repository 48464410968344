/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import CN from 'classnames';

import { ReactComponent as CardIcon } from 'containers/account/BuyCredits/assets/card.svg';
import { ReactComponent as WalletIcon } from 'containers/account/BuyCredits/assets/wallet.svg';

import { copyToClipboard, lengthAfterDot } from 'utils/string';
import authCookiesRequest from 'utils/request/auth-request-cookies';
import { NEIRA_AI_API } from 'constants/api-urls';

import styles from './styles.module.scss';
import { CopyButton } from 'components/Button';
import { BIOS_PRICE } from 'constants/bios-price';
import {
  checkTokenPurchaseStatusInLocalStorage,
  deleteTokenPurchaseStatusFromLocalStorage,
} from 'utils/tokenPurchase';
import { addNotification } from 'store/main/actions';

const ENDPOINT = `${NEIRA_AI_API}/gateway/billing`;

const BuyToken = () => {
  const history = useHistory();
  const [inputValue, setInputValue] = useState('100');
  const [type, setType] = useState('card');
  const [address, setAddress] = useState('');

  const location = useLocation();
  const [buyToActivateBios, setBuyToActivateBios] = useState(false);

  useEffect(() => {
    if (location.state?.price && location.state?.price === BIOS_PRICE) {
      setBuyToActivateBios(true);
      setInputValue(BIOS_PRICE.toString());
    }
  }, [location.state]);

  const retrieveCrypto = async () => {
    const endpoint = `${NEIRA_AI_API}/gateway/billing/retrieve_crypto`;

    try {
      const response = await authCookiesRequest.post(endpoint, {
        symbol: 'USDT',
        chain: 'trx',
      });
      if (response.status !== 200) {
        return response;
      }
      setAddress(response.data.data?.address);
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  };

  const changeValue = ({ target: { value } }) => {
    // Перевірка, чи введене значення менше 1 або не є числом
    if (value === '') {
      setInputValue('');
    } else if (value < 1 || isNaN(value)) {
      setInputValue(1);
    } else {
      const info = lengthAfterDot(value);
      if (info > 2) {
        setInputValue(parseFloat(value).toFixed(2));
      } else {
        setInputValue(value);
      }
    }
  };

  const onCopyValue = () => {
    copyToClipboard(address);
  };

  const topUpBalance = async (amount) => {
    const endpoint = `${ENDPOINT}/topup_balance`;

    try {
      const response = await authCookiesRequest.post(endpoint, {
        amount: Math.ceil(Number(amount) / 10) * 10,
      });

      if (response.data && response.data.data.payment_link) {
        window.location.assign(response.data.data.payment_link);
      } else {
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const result = queryParams.get('result');
    const activateBios = checkTokenPurchaseStatusInLocalStorage();
    if (result === 'success') {
      addNotification('Payment success', 'success', false, '200px', true, 1000);
      if (activateBios) {
        history.push({
          pathname: '/registration',
          state: { successPayment: true },
        });
        deleteTokenPurchaseStatusFromLocalStorage();
      }
    } else if (result === 'canceled') {
      addNotification('Payment canceled', 'alert', false, '200px', true, 1000);
      if (activateBios) {
        setBuyToActivateBios(true);
        setInputValue(BIOS_PRICE.toString());
        return;
      }
    } else {
      if (activateBios) {
        setBuyToActivateBios(true);
        setInputValue(BIOS_PRICE.toString());
      }
    }
  }, []);

  return (
    <div
      className={CN(
        styles.buyTokenWrapper,
        type === 'card' && styles.buyTokenWrapperCard
      )}
    >
      <div>
        <h1 className={styles.title}>Buy Credits</h1>
        <p className={styles.description}>
          {`Opt for flexibility: 'Pay as you go' with Neyra Token. Equal to 'Neyra Credits,'`}{' '}
          <strong>1 credit is $0.01</strong>. Simplify transactions with this
          utility credit. Max purchase: $5,000.
        </p>
        <div className={styles.selector}>
          <div className={styles.selectorAmount}>
            <div className={styles.selectorAmountValue}>
              <div className={styles.inputContent}>
                <input
                  className={styles.input}
                  type="number"
                  onChange={changeValue}
                  value={inputValue}
                  disabled={buyToActivateBios}
                />
                <span className={styles.inputCurrency}>USD</span>
              </div>
              <p className={styles.valueText}>Amount</p>
            </div>
          </div>
          <div className={styles.border} />
          <div className={styles.selectorCredits}>
            <div className={styles.selectorCreditsContent}>
              <p className={styles.creditsValue}>
                {(parseFloat(inputValue) * 100).toLocaleString()}
              </p>
              <p className={styles.creditsText}>Credits</p>
            </div>
          </div>
        </div>
        <div className={styles.paymentMethod}>
          <button
            onClick={() => {
              setType('card');
            }}
            className={CN(
              styles.paymentBtn,
              type === 'card' && styles.paymentBtnActive
            )}
          >
            <CardIcon />
            <span className={styles.paymentBtnText}>Card</span>
          </button>
          <button
            onClick={() => {
              setType('wallet');
              retrieveCrypto();
            }}
            className={CN(
              styles.paymentBtn,
              type === 'wallet' && styles.paymentBtnActive
            )}
          >
            <WalletIcon />
            <span className={styles.paymentBtnText}>Crypto </span>
          </button>
        </div>
      </div>
      <div className={styles.payForm}>
        {type === 'card' ? (
          <button
            className={styles.payBtn}
            onClick={() => {
              topUpBalance(+inputValue * 100);
            }}
          >
            Pay
          </button>
        ) : (
          <div className={styles.web3Payment}>
            <p className={styles.web3PaymentDesc}>
              *For web3 users
              <br />
              Please note that Web3 transactions may take approximately 2-5
              minutes to reflect in your Neyra Credits balance.
            </p>
            <div className={styles.web3Form}>
              <div className={styles.web3FormItem}>
                <p className={styles.web3FormItemLabel}>Currency</p>
                <div className={styles.web3FormItemInput}>USDT</div>
              </div>
              <div className={styles.web3FormItem}>
                <p className={styles.web3FormItemLabel}>Chain</p>
                <div className={styles.web3FormItemInput}>Chain</div>
              </div>
              <div className={styles.web3FormItem}>
                <p className={styles.web3FormItemLabel}>Address</p>
                <div className={styles.web3FormItemInput}>
                  <span className={styles.addressInput}>{address}</span>
                  <CopyButton onClick={onCopyValue} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyToken;
