/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import NeyraBorder from 'components/NeyraBorder';
import NeyraSidebar from 'components/NeyraSidebar';
import AuthContent from './components/AuthContent';
import AuthSidebar from './components/AuthSidebar';
import { MainContextWrapper } from 'store/context';
import NotificationBubble from 'containers/notifications/notification-bubble';

import getToken from 'utils/auth/get-token';
import useNotification from 'utils/hooks/use-notification';
import { validationRegExp } from 'utils/validation/regexp'
import { createAccess, updateAvatar } from 'store/neyra/effects';
import { selectNeyraProfile, selectNeyraAvatar, selectNeyraAvatarLoading } from 'features/neyra';

import styles from './styles.module.scss';
import { isEmpty } from 'lodash';

export const componentStateList = {
  logo: 'logo',
  city: 'city',
};

const formMode = {
  edit: 'edit',
  setup: 'setup',
};

const initialValue = {
  domain: '',
  code: '',
};

const scenarioList = {
  0: { name: 'domain', value: 0 },
  1: { name: 'code', value: 1 },
};

const getEditStep = (name) => {
  return Object.values(scenarioList).find((item) => item.name === name).value;
};

const errorMsg = {
  already: "Access already granted, one time use remaining",
}

const PublishAvatar = ({ avatar, avatarLoading }) => {
  const { addNotification } = useNotification();
  const history = useHistory();
  const [editStep, setEditStep] = useState(scenarioList[0].value);
  const [mode, setMode] = useState(formMode.setup);
  const timeRef = useRef(null);
  const [cityList, setCityList] = useState([]);
  const [values, setValues] = useState(initialValue);
  const [contentState, setComponentState] = useState('');
  const [logo, setLogo] = useState('');
  const isAuth = !!getToken();
  const [inputValue, setInputValue] = useState('');
  const isFormValid = Boolean(
    values.domain &&
    values.code &&
    values.code.length === 6
  );
  const avatarItem = avatar.length ? avatar[0]: {}

  useEffect(() => {
    if (!avatarLoading && isAuth) {
      if (isEmpty(avatarItem)) {
        history.push('/');
      } else {
        if (!validationRegExp.uuidRegex.test(avatarItem?.profile.slug)) {
          history.push('/');
        }
      }
    }
  }, [isAuth, avatarItem, avatarLoading])

  const onPublish = async () => {
    createAccess(values.code)
      .then((data) => {
        if (data.message === errorMsg.already) {
          addNotification(data?.message, 'success');
        } else {
          updateAvatarEffect()
        }
      })
      .catch((erro) => {
        addNotification(erro?.message, 'alert');
      })
  };

  const updateAvatarEffect = async () => {
    const data = await updateAvatar(avatarItem.id, values.domain)
    if (data.status === "success") {
      addNotification('Avatar was published', 'success');
      history.push(`/${values.domain}`)
    } else {
      addNotification(data.message || 'Something was wrong', 'alert');
    }
  }

  const onValueChangeHandler = (myValues) => {
    if (mode === formMode.setup) {
      setValues(myValues);
    } else {
      setValues(myValues);
    }
  };

  const onEnterPress = (name) => {
    const value = values[name];
    if (editStep <= Object.keys(scenarioList).length && value) {
      setEditStep(editStep + 1);
    }
  };

  const onFieledFocus = (_, name) => {
    setEditStep(getEditStep(name));
  };

  return (
    <div className={styles.container}>
      <NeyraBorder showCircles={false} containerClass={styles.noBackground}>
        <AuthContent
          inputValue={inputValue}
          contentState={contentState}
          isEditMode={mode === formMode.edit}
          setLogo={setLogo}
          setValues={setValues}
          setInputValue={setInputValue}
          logo={logo}
          list={cityList}
          timeRef={timeRef}
          setCityList={setCityList}
        />
      </NeyraBorder>
      <NeyraSidebar>
        <AuthSidebar
          timeRef={timeRef}
          isEditMode={mode === formMode.edit}
          values={values}
          setValues={onValueChangeHandler}
          setComponentState={setComponentState}
          setInputValue={setInputValue}
          logo={logo}
          cityList={cityList}
          onPublish={onPublish}
          setCityList={setCityList}
          editStep={editStep}
          onEnterPress={onEnterPress}
          onFieledFocus={onFieledFocus}
          isFormValid={isFormValid}
        />
      </NeyraSidebar>
      <NotificationBubble />
    </div>
  );
};

const mapStateToProps = (store) => ({
  profile: selectNeyraProfile(store),
  avatar: selectNeyraAvatar(store),
  avatarLoading: selectNeyraAvatarLoading(store)
});

const PublishAvatarContainer = (props) => (
  <MainContextWrapper>
    <PublishAvatar {...props} />
  </MainContextWrapper>
);

export default connect(mapStateToProps)(PublishAvatarContainer);
