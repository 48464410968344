/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isString } from 'lodash';
import uuid from 'react-uuid';
import moment from 'moment';

import NeyraBorder from 'components/NeyraBorder';
import NeyraWelcomeSidebar from 'components/NeyraWelcomePageSidebar';
import NeyraSidebar from 'components/NeyraSidebar';
import AuthContent from './components/AuthContent';
import SeputContent from './components/SetupContent';
import AuthSidebar from './components/AuthSidebar';

import BiosFigure from './components/BiosFigure';
import getToken from 'utils/auth/get-token';
import useEnv from 'utils/hooks/use-env';

import {
  getCityList,
  uploadAvatar,
  createAvatar,
  createBios,
} from 'store/neyra/effects';
import { selectNeyraProfile, selectNeyraAvatar } from 'features/neyra';
import { NEIRA_AI_API_BASE } from 'constants/api-urls';
import NeyraSmallLogo from 'components/NeyraSmallLogo';

import styles from './styles.module.scss';

export const componentStateList = {
  logo: 'logo',
  city: 'city',
};

const previewModeList = {
  auth: 1,
  setup: 2,
};

const formMode = {
  edit: 'edit',
  setup: 'setup',
};

const initialValue = {
  name: '',
  description: '',
  date: '',
  city: '',
  time: '',
  timeZone: '',
  images: 1000,
  voice: 64,
  drive: 1,
  tokens: 1,
  profit: 70,
};

const scenarioList = {
  0: { name: 'name', value: 0 },
  1: { name: 'date', value: 1 },
  2: { name: 'city', value: 2 },
  3: { name: 'time', value: 3 },
  4: { name: 'description', value: 4 },
  5: { name: 'image', value: 5 },
};

const getEditStep = (name) => {
  return Object.values(scenarioList).find((item) => item.name === name).value;
};

const NeyraBios = ({ profile, avatar }) => {
  const { t } = useTranslation('auth');
  const [previewMode, setPreviewMode] = useState(previewModeList.auth);
  const [editStep, setEditStep] = useState(scenarioList[0].value);
  const [mode, setMode] = useState(formMode.setup);
  const timeRef = useRef(null);
  const [cityList, setCityList] = useState([]);
  const [values, setValues] = useState(initialValue);
  const [contentState, setComponentState] = useState('');
  const [logo, setLogo] = useState('');
  const isAuth = !!getToken();
  const history = useHistory();
  const currentRoute = location.pathname.replace('/', '');
  const [inputValue, setInputValue] = useState('');
  const isDevEnviroment = useEnv();
  const isFormValid = Boolean(
    values.name &&
      values.date &&
      values.city &&
      values.time &&
      values.description &&
      logo
  );

  const info = [
    { text: t('bios.bpm'), revers: false },
    { text: t('bios.mood'), revers: true },
    { text: t('bios.energy'), revers: false },
    { text: t('bios.activity'), revers: true },
  ];

  useEffect(() => {
    if (editStep === 0) {
      setInputValue(values.name);
    } else if ([1, 2, 3].includes(editStep)) {
      setInputValue(
        [values.date, values.city, values.time]
          .filter((item) => !!item)
          .join(', ')
      );
    } else if (editStep === 4) {
      setInputValue(values.description);
    } else {
      setInputValue('');
    }
  }, [values, editStep]);

  useEffect(() => {
    if (values.city && editStep === 2) {
      setComponentState(componentStateList.city);
      getCityList(values.city).then((data) => {
        setCityList(data?.data || []);
      });
    } else if (editStep !== 2) {
      setCityList([]);
    }
  }, [values.city, editStep]);

  useEffect(() => {
    if (mode === formMode.setup && editStep === 5) {
      setComponentState(componentStateList.logo);
    }
  }, [editStep, mode]);

  useEffect(() => {
    if (!isEmpty(profile) && !isEmpty(avatar)) {
      const userAvatar = avatar[0].profile;
      const myProfile = profile.profile;

      const initialValues = {
        name: userAvatar.name,
        description: userAvatar.mission,
        date: moment(myProfile.date_of_birth).format('MM.DD.YYYY'),
        city: myProfile.place_of_birth,
        time: myProfile.time_of_birth,
        timeZone: '',
      };
      setValues(initialValues);
      setMode(formMode.edit);
      setComponentState(componentStateList.logo);
      setLogo(
        userAvatar.image ? `${NEIRA_AI_API_BASE}${userAvatar.image}` : ''
      );
    }
  }, [profile, avatar]);

  const onLogoClick = () => history.push('/');

  const onPublish = async () => {
    try {
      let avatarLogo;
      if (isString(logo)) {
        avatarLogo = {
          data: { data: { path: logo.substring(NEIRA_AI_API_BASE.length) } },
        };
      } else {
        avatarLogo = await uploadAvatar(logo);
      }

      await createAvatar({
        name: values.name,
        mission: values.description,
        public: true,
        code: '',
        slug: uuid(),
        llm: 1,
        image: avatarLogo?.data?.data?.path || '',
      });
      const splitedDate = values.date.split('.');
      await createBios({
        gender: 'M',
        place_of_birth: values.city,
        date_of_birth: `${[splitedDate[2], splitedDate[0], splitedDate[1]].join(
          '-'
        )} ${values.time}`,
        place_of_birth_timezone: values.timeZone,
      });

      setPreviewMode(previewModeList.setup);
    } catch (error) {
      console.warn(error);
    }
  };

  const onValueChangeHandler = (myValues) => {
    if (mode === formMode.setup) {
      setValues(myValues);
    } else {
      setValues(myValues);
    }
  };

  const onEnterPress = (name) => {
    const value = values[name];
    if (editStep <= Object.keys(scenarioList).length && value) {
      if (name === 'city') {
        if (cityList.length) {
          setValues((values) => ({
            ...values,
            city: cityList[0].city,
            timeZone: cityList[0].tz,
          }));
        } else {
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          setValues((values) => ({
            ...values,
            timeZone: timeZone,
          }));
        }
        setCityList([]);
        setComponentState('');
        document.getElementById('time-input').focus();
      }
      setEditStep(editStep + 1);
    }
  };

  const onFieledFocus = (_, name) => {
    setEditStep(getEditStep(name));
  };

  if (!isAuth || !isDevEnviroment)
    return (
      <div className={styles.container}>
        <div className={styles.absolute}>
          <spline-viewer
            url={'https://prod.spline.design/dSpsyZPwX-RcZLJX/scene.splinecode'}
          ></spline-viewer>
        </div>
        <NeyraBorder containerClass={styles.noBackground}>
          <NeyraSmallLogo
            className={styles.NeyraLogo}
            handleClick={onLogoClick}
          />
          <div className={styles.wrapper}>
            <div className={styles.infoBlock}>
              {info.map((item, index) => (
                <div
                  key={item.text}
                  className={`${styles.block} ${
                    index === 0
                      ? styles.firstFigure
                      : index === 3
                      ? styles.lastFigure
                      : ''
                  }`}
                >
                  <BiosFigure text={item.text} revers={item.revers} />
                </div>
              ))}
            </div>
          </div>
        </NeyraBorder>
        <NeyraWelcomeSidebar type={currentRoute} />
      </div>
    );

  return (
    <div className={styles.container}>
      <NeyraBorder showCircles={false} containerClass={styles.noBackground}>
        {previewMode === previewModeList.auth ? (
          <AuthContent
            inputValue={inputValue}
            contentState={contentState}
            isEditMode={mode === formMode.edit}
            setLogo={setLogo}
            setValues={setValues}
            setInputValue={setInputValue}
            logo={logo}
            list={cityList}
            timeRef={timeRef}
            setCityList={setCityList}
          />
        ) : (
          <SeputContent />
        )}
      </NeyraBorder>
      <NeyraSidebar>
        <AuthSidebar
          timeRef={timeRef}
          isEditMode={mode === formMode.edit}
          values={values}
          setValues={onValueChangeHandler}
          setComponentState={setComponentState}
          setInputValue={setInputValue}
          logo={logo}
          cityList={cityList}
          onPublish={onPublish}
          setCityList={setCityList}
          setupState={previewMode === previewModeList.setup}
          editStep={editStep}
          onEnterPress={onEnterPress}
          onFieledFocus={onFieledFocus}
          isFormValid={isFormValid}
        />
      </NeyraSidebar>
    </div>
  );
};

const mapStateToProps = (store) => ({
  profile: selectNeyraProfile(store),
  avatar: selectNeyraAvatar(store),
});

export default connect(mapStateToProps)(NeyraBios);
