import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

import {
  setNeyraWrites,
  createMessage,
} from 'features/neyra';
import { sendMessage } from 'store/neyra/effects'

import useNotification from 'utils/hooks/use-notification';
import getToken from 'utils/auth/get-token';

let controller;

const useMemoMessanger = () => {
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const { autoSpeaker } = useSelector((state) => state.speaker);
  const { conversationUid, memoMessage } = useSelector((state) => state.neyra);

  const sendMessageMemoProcess = async (
    message,
    hideMyMessage,
  ) => {
    const nextMessage = `${message} "${memoMessage}"`;
    dispatch(setNeyraWrites(true));
    controller = new AbortController();
    const token = getToken();
    !hideMyMessage &&
      dispatch(
        createMessage({
          id: uuid(),
          text: message,
          voice: false,
          timeStamp: new Date(),
          isNeyro: false,
        })
      );

    const result = await sendMessage(
      token,
      conversationUid,
      nextMessage,
      null,
      false,
      controller,
      autoSpeaker,
      null,
      1,
      { chat_mode: 'memories'  }
    );


    if (result?.status === 402) {
      addNotification(t('chat.notEnoughCredits'), 'alert');
      dispatch(setNeyraWrites(false));
      return;
    }

    if (result) {
      dispatch(setNeyraWrites(false));
    } else {
      dispatch(setNeyraWrites(false));
      addNotification('Error!', 'alert');
    }
  };


  return { sendMessageMemoProcess }
}

export { useMemoMessanger }