/* eslint-disable no-unused-vars */
//Refactoring №2
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { isNull } from 'lodash';
import { downloadFileFromSP } from 'gdgateway-client/lib/es5/downloadFile/downloadFileFromSP';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';

import MainContext from 'store/main/context/main-context';
import { updateFileEffect } from 'store/home/effects/file/file-update.effect';
import UserContext from 'store/home/contexts/user-context';
import TokenCostContext from 'store/tokenCost/contexts';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
  selectTokenCountState,
} from 'features/app';
import { selectNeyrAIFiles } from 'features/neyra';

import {
  handleEditModal,
  handleGeoModal,
  handleNotEnoughTokenModal,
  handleConvertModal,
  handleDeleteModal,
} from 'features/modals/modal-slice';
import { updateDocumentSuccess } from 'store/home/actions/documents.actions.js';
import { getFilesUpdate } from 'store/home/actions/files/get-files.actions';
import { getFilePreview } from 'store/home/effects/file/get-image-preview.effect';
import { isMember } from 'store/home/selectors';
import Tooltip from 'components/shared/Tooltip/tooltip';
import ContextMenu from 'components/context-menu';
import SecuredIcon from 'components/svg/secured';
import EncryptedIcon from 'components/svg/encrypted';
import SharedIcon from 'components/svg/shared';
import DiamondIcon from 'components/svg/diamond';
import Spinner from 'components/Spinners';
import CustomFileIcon from 'components/CustomFileIcon/CustomFileIcon.tsx';
import CustomIconHint from 'components/CustomFileIcon/CustomIconHint';
import CustomFileSmallIcon from 'components/CustomFileIcon/CustomFileSmallIcon';
import ContextMenuButton, {
  contextMenuButtonStyle,
} from 'components/ContextMenuButton';
import Checkbox, { checkboxStyle } from 'components/Checkbox';
import { ReactComponent as PlayIcon } from 'components/CustomFileIcon/assets/smallFileIcons/play.svg';
import { ReactComponent as StareIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/star.svg';
import { ReactComponent as SharedUserIcon } from 'static/assets/svg/shared-user-icon.svg';
import AnalyticsModal from 'components/AnalyticsModal';
import { AllowManageKeyPopup } from 'containers/main/Main/components/Sharing/components/AllowManageKeyPopup';

import authRequest from 'utils/request/auth-request';
import { useClickHanler } from 'utils/hooks/use-click-handler';
import { validationRegExp } from 'utils/validation/regexp';
import { isMetamaskAddress } from 'utils/crypto/validation';
import useClickOutside from 'utils/hooks/use-click-outside';
import { extractShareRightsForUser } from 'utils/shares';
import Users from '../main/EntitySettingsContainer/components/InfoTab/MetaTab/users';
import calculatePositionByElement from 'utils/calculate-position-by-element';
import CreateEntityModal from '../header/header-container/create-entity-modal';
import { transformSize } from 'utils/storage';
import { TOKEN_COST } from 'config/token-cost';
import { isMobile } from 'utils/mobile';
import useNotification from 'utils/hooks/use-notification';
import { encryptAction } from 'utils/file/encryptAction';

import dndTypes from 'config/dnd-types';
import { entitiesTypes } from 'config/entities-types';
import actionsOptions from 'config/actions-options';
import { EMBARGO_MULTISIG_OPTIONS } from 'config/multisig';
import imageFileExtensions, {
  imageMediaTypesPreview,
  imagesWithoutPreview,
} from 'config/image-file-extensions';
import { docMediaTypesPreview } from 'config/docs-file-extensions';
import { videoMediaExtentionPreview } from 'config/video-file-extensions';

import style from './style.module.css';

export const FileSource = {
  beginDrag(props) {
    const { file, onDragBegin, selectedSlugs } = props;
    if (selectedSlugs.includes(file.slug) || !selectedSlugs.length)
      onDragBegin(file, false);
    else onDragBegin(file, true);

    return {
      file,
    };
  },
  canDrag(props) {
    const { canDrag } = props;

    return canDrag;
  },

  endDrag(props) {
    const { onDragEnd } = props;

    onDragEnd();

    return false;
  },
};

export function collectDragSource(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
    didDrop: monitor.didDrop(),
    getDifferenceFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
  };
}

export const FileContainer = ({
  showFile,
  file,
  onOpenGhostTiming,
  fileFolderActionHandlerEffect,
  isSelected,
  isDocument,
  selectedFilesRemoveEffect,
  options,
  isDragged,
  isEdit,
  disabled,
  showSharing,
  showSize,
  canSelect,
  viewType,
  connectDragSource,
  connectDragPreview,
  selectedFilesAddEffect,
  selectedEntity,
  showType,
  isGuest,
  hideActions,
  disableClick,
  disableDoubleClick,
  trash,
  optionHandler,
  isPublic,
  forwardedRef,
  onCheckChange,
  gatewayData = null,
}) => {
  const { t: contextT } = useTranslation('contextMenu');
  const { t: ownerT } = useTranslation('owner');
  const { t: commonT } = useTranslation('common');
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);
  const { tokenCost } = useContext(TokenCostContext);
  const [name, setName] = useState('');
  const [analyticsIsOpen, setAnalyticsIsOpen] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [showContext, setShowContext] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [isDownloadedByGuest, setIsDownloadedByGuest] = useState(false);
  const [isAllowManagePopupOpen, setIsAllowManagePopupOpen] = useState(false);
  const [isFloatedPreview, setFloatedPreview] = useState(false);
  const [floatedPreviewPosition, setFloatedPreviewPosition] = useState({
    x: 0,
    y: 0,
  });
  const is360Panorama = file.mime === 'hdri';
  const hoverStartTimeRef = useRef(0);

  const { addNotification } = useNotification();
  const entityView = useSelector(
    (state) => state?.home?.selectedEntity?.entityView
  );
  const [encryptingFiles, setEncryptingFiles] = useState([]);
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const neyraFile = useSelector(selectNeyrAIFiles).find(
    (item) => item.uid === file.slug
  );
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const userTokens = parseFloat(useSelector(selectTokenCountState));
  const showEncryptionModal = useSelector(
    (state) => state.home.encrypt.showEncryptionKeyModal
  );
  const isNeyraFile = !!neyraFile;
  const isNeyraFileProccess = isNeyraFile && !neyraFile?.ai;

  const [contextPosition, setContextPosition] = useState({
    top: '10px',
    left: '50%',
  });
  const {
    state: { pageName },
  } = useContext(MainContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const editContainerRef = useRef(null);
  const fileItemRef = useRef(null);
  const isSelectedEntity = useMemo(
    () => selectedEntity?.entity?.slug === file?.slug,
    [selectedEntity, file]
  );
  const created = useMemo(
    () => (file.created_at ? moment.unix(file.created_at) : moment()),
    [file]
  );
  const sharePermissions = extractShareRightsForUser(user?.id, file.shares);
  const fileSize = transformSize(file?.size);
  const isGuestAccount =
    (isGuest || user.isGuest) &&
    (user.role !== 'ROLE_OWNER' || pathname === '/sharing/with-me');

  const imageTypeForAiGeneratorSet = new Set([
    'jpg',
    'jpeg',
    'JPG',
    'JPEG',
    'png',
    'webp',
  ]);
  const isImageType = imageTypeForAiGeneratorSet.has(file.extension);
  const hasMetamaskShares = useMemo(
    () =>
      file?.shares?.some((item) => isMetamaskAddress(item?.share_right?.input)),
    [file]
  );
  const hasUserShares = useMemo(
    () =>
      file?.shares?.some(
        (item) =>
          validationRegExp.email.test(item?.share_right?.input) ||
          isNull(item?.share_right?.input) ||
          item.type === 1
      ),
    [file]
  );
  const isShared = useMemo(
    () => hasMetamaskShares || hasUserShares,
    [hasMetamaskShares, hasUserShares]
  );
  const isUserHasThePublicAddres =
    window?.ethereum?.selectedAddress !== undefined;
  const isMinted = useMemo(
    () => !!file?.entry_groups?.some((item) => item.is_tokenized),
    [file]
  );
  const isRightBarOpenClassName = useMemo(
    () => (selectedEntity?.accessAllow ? 'file-non-display-option' : ''),
    [selectedEntity]
  );

  const openHint = () => setShowHint(true);
  const closeHint = () => setShowHint(false);

  useEffect(() => {
    const necessaryFile = localStorage.getItem('FILE_SLUG');
    if (necessaryFile === file.slug) {
      const viewAction = getViewAction();
      fileFolderActionHandlerEffect(file, viewAction, {}, history);
      localStorage.removeItem('FILE_SLUG');
    }
    return () => {
      localStorage.removeItem('FILE_SLUG');
    };
  }, []);

  useEffect(() => {
    if (
      pageName !== 'Deleted' &&
      file?.slug &&
      ((imageFileExtensions.includes(`.${file.extension}`) &&
        !imagesWithoutPreview.includes(`.${file.extension}`)) ||
        docMediaTypesPreview.includes(file.mime) ||
        videoMediaExtentionPreview.includes(file?.extension?.toLowerCase()))
    ) {
      let isPreviewAssigned = false;

      if (file?.thumbnail) {
        setFilePreview(file?.thumbnail);
        isPreviewAssigned = true;
      }
      if (!file?.thumbnail && file?.preview_small && gatewayData) {
        getFilePreview(file, gatewayData).then((res) => {
          if (res) {
            setFilePreview(res);
            isPreviewAssigned = true;
          }
        });
      }
      if (isPreviewAssigned) {
        return;
      }
    }
  }, [
    file.mime,
    file.service,
    file.slug,
    isPublic,
    viewType,
    pageName,
    gatewayData,
  ]);

  useLayoutEffect(() => {
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }, []);

  const imageHasPreview = useMemo(
    () =>
      !file?.securities?.length &&
      (imageMediaTypesPreview.includes(file?.mime) ||
        docMediaTypesPreview.includes(file?.mime) ||
        videoMediaExtentionPreview.includes(file?.extension?.toLowerCase())),
    [file]
  );

  const onFileClickHandler = useCallback(
    (e) => {
      if (!disableClick && !showContext) {
        const { goToSettings } = actionsOptions;

        const target = e.target;
        const parent = target.parentElement;
        const targetClasses = target.className;
        const parentClasses = parent.className;

        const isActionElem =
          !(targetClasses instanceof SVGAnimatedString) &&
          (targetClasses.indexOf('action') >= 0 ||
            parentClasses.indexOf('action') >= 0);
        const isCheckBoxElem =
          !(targetClasses instanceof SVGAnimatedString) &&
          (targetClasses.indexOf('checkbox') >= 0 ||
            parentClasses.indexOf('select-item-checkbox') >= 0);

        if (
          !isActionElem &&
          !isCheckBoxElem &&
          !e.shiftKey &&
          !isEdit &&
          !isDownloadedByGuest &&
          !user.isGuest
        ) {
          fileFolderActionHandlerEffect(file, goToSettings);
        }

        if (e.shiftKey) {
          onFileSelectHandler();
        }
      }
    },
    [disableClick, showContext, isEdit, isDownloadedByGuest, user]
  );

  const getViewAction = () => {
    const { viewFile, openLink, openPassword } = actionsOptions;

    switch (file.extension) {
      case 'vault':
        return openPassword;
      case 'link':
        return openLink;
      default:
        return viewFile;
    }
  };

  const onFileDoubleClickHandler = (e) => {
    e.stopPropagation();
    if (!disableDoubleClick && !showContext) {
      const viewAction = getViewAction();

      const target = e.target;
      const parent = target.parentElement;
      const targetClasses = target.className;
      const parentClasses = parent.className;

      const isActionElem =
        !(targetClasses instanceof SVGAnimatedString) &&
        (targetClasses.indexOf('action') >= 0 ||
          parentClasses.indexOf('action') >= 0);
      const isCheckBoxElem =
        !(targetClasses instanceof SVGAnimatedString) &&
        targetClasses.indexOf('checkbox') >= 0;

      if (
        !isActionElem &&
        !isCheckBoxElem &&
        !e.shiftKey &&
        !isEdit &&
        !isDownloadedByGuest
      ) {
        fileFolderActionHandlerEffect(file, viewAction, {}, history);
      }
    }
  };

  const onRightFileClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.clientY === 0 || e.clientX === 0) return;

    const position = calculatePositionByElement(e, contextOptions().length);
    setContextPosition(position);
    setShowContext(true);
  };

  const onClickOutsideContext = () => {
    setShowContext(false);
  };

  const getSplitedText = (name) => {
    const fileName = file.extension === 'memo' ? `${name}.memo` : name;
    const splitedName = fileName.split('.');
    if (splitedName) {
      return {
        name:
          splitedName.length >= 1
            ? splitedName.slice(0, -1).join('.')
            : splitedName[0],
        extention: splitedName[splitedName.length - 1],
      };
    } else {
      return {
        name: name,
        extention: null,
      };
    }
  };

  const aiHandler = (costId, action) => {
    const aiCost = tokenCost.reduce((_, item) => {
      if (costId === item.id) {
        return item.tokens;
      }
      return _;
    }, 0);
    if (userTokens >= aiCost) {
      action();
    } else {
      dispatch(handleNotEnoughTokenModal(true));
    }
  };

  useEffect(() => {
    if (file && file.name) {
      const data = getSplitedText(file.name);
      setName(data.name);
    }
  }, [file]);

  useEffect(() => {
    if (isMember(user) && file.is_clientside_encrypted) {
      showEncryptionModal === file.is_clientside_encrypted &&
        setIsDownloadedByGuest(true);
    }
  }, [showEncryptionModal, file]);

  const actionHandler = (option) => {
    if (option.type === actionsOptions.encrypt.type) {
      const isAlreadyEncrypting = encryptingFiles.find(
        (slug) => slug === file.slug
      );
      if (!isAlreadyEncrypting) {
        setEncryptingFiles((prev) => [...prev, file.slug]);
        const afterCb = () => {
          const clearedFiles = encryptingFiles.filter(
            (slug) => slug !== file.slug
          );
          setEncryptingFiles(clearedFiles);
        };
        encryptAction({
          user,
          userTokens,
          file,
          dispatch,
          addNotification,
          afterCb,
        });
      }
    }
    if (
      [
        actionsOptions.removeFromTrash.type,
        actionsOptions.restoreFromTrash.type,
        actionsOptions.removeFromShared.type,
        actionsOptions.removeShared.type,
        actionsOptions.showMove.type,
        actionsOptions.myAI.type,
      ].includes(option.type)
    ) {
      optionHandler(file, option, entitiesTypes.file.type);
      return;
    }
    if ([actionsOptions.remove.type].includes(option.type)) {
      dispatch(handleDeleteModal({ isOpen: true, entity: file }));
      return;
    }
    if (option.type === actionsOptions.aiUpscaller.type) {
      aiHandler(TOKEN_COST.AIImageUpscale, () => {
        fileFolderActionHandlerEffect(file, option);
      });
      return;
    }
    if (option.type === actionsOptions.aiGenerator.type) {
      aiHandler(TOKEN_COST.AIImage2Image, () => {
        fileFolderActionHandlerEffect(file, option);
      });
      return;
    }
    if (
      [
        actionsOptions.guardians.type,
        actionsOptions.geoSecurity.type,
        actionsOptions.accessPreferences.type,
      ].includes(option.type)
    ) {
      fileFolderActionHandlerEffect(file, option, { skipGetData: true });
      return;
    }
    if (option.type === actionsOptions.edit.type) {
      fileFolderActionHandlerEffect(file, option, { skipGetData: true });
      setName(file.name.split('.')[0]);
      return;
    }
    if (option.type === actionsOptions.geoSecurity.type) {
      dispatch(handleGeoModal({ modal: true, entity: file }));
      return;
    }
    if (option.type === actionsOptions.timing.type) {
      onOpenGhostTiming(file, fileFolderActionHandlerEffect, option);
      return;
    }
    if (option.type === actionsOptions.nft.type) {
      if (!file.is_clientside_encrypted) {
        fileFolderActionHandlerEffect(file, actionsOptions.goToTokenization);
        return;
      } else {
        addNotification(
          'This file group contains encrypted files which may not be tokenized',
          'alert'
        );
      }
    }
    if (option.type === actionsOptions.convert.type) {
      if (file.is_clientside_encrypted) {
        addNotification(
          contextT('files.cannotConvertEncryptedFile'),
          'warning'
        );
      } else {
        dispatch(handleConvertModal({ modal: true, entity: file }));
      }
      return;
    }
    fileFolderActionHandlerEffect(file, option);
  };

  const onEndEditHandler = (newName) => {
    setName(file.name);
    const nameOject = getSplitedText(file.name);
    const extentionPart =
      nameOject.extention && file.extension !== 'memo'
        ? `.${nameOject.extention}`
        : '';
    updateFileEffect(file, { name: `${newName}${extentionPart}` }).then(
      (data) => {
        if (isDocument) dispatch(updateDocumentSuccess(data));
        else {
          if (entityView && data.slug === entityView.slug) {
            fileFolderActionHandlerEffect(data, actionsOptions.viewFile);
          }
          dispatch(
            getFilesUpdate({ entity: data, updatedPropsList: ['name'] })
          );
        }
        dispatch(handleEditModal(false));
      }
    );
  };

  const onCancelEditHandler = () => {
    dispatch(handleEditModal(false));
    setName(file.name);
  };

  useClickOutside(editContainerRef, onCancelEditHandler);

  const onFileSelectHandler = () => {
    if (!isSelected) {
      selectedFilesAddEffect({ entities: file });
      onCheckChange(true, file);
    } else {
      selectedFilesRemoveEffect(file);
      onCheckChange(false, file);
    }
  };

  const renderFileName = () => {
    const maxLength = 20;
    const sliceName = () => file?.name?.slice(0, maxLength).trim() + '...';

    return file?.name?.length <= maxLength ? (
      file?.name
    ) : (
      <>
        {sliceName()}
        <Tooltip text={file.name} className={style.fileNameTooltip} />
      </>
    );
  };

  const aiFavHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    actionHandler(actionsOptions.myAI);
  };

  const renderActions = () => {
    if (hideActions) return null;
    const canRender = !isDragged && !disabled && options.length > 0;

    if (viewType === 'square') {
      return (
        <button
          onClick={aiFavHandler}
          className={cn(
            'file-action-avatar',
            file?.user_favorites?.length && 'file-action-avatar-active',
            (isNeyraFileProccess || (isNeyraFile && !isNeyraFileProccess)) && [
              'file-action-avatar-show',
              'file-action-avatar-active',
            ]
          )}
        >
          {isNeyraFileProccess ? <Spinner /> : <StareIcon />}
        </button>
      );
    }

    return (
      canRender && (
        <ContextMenuButton
          className="file-action"
          onClick={onRightFileClickHandler}
          buttonStyle={
            imageHasPreview && viewType === 'square'
              ? contextMenuButtonStyle.LIGHT
              : contextMenuButtonStyle.DARK
          }
          data-test="file-container_context-menu_button"
        />
      )
    );
  };
  actionsOptions.viewFile.className = 'context-menu-item-divider';

  const contextFilter = (option) => {
    if (!option) return false;
    if (file.extension === 'vault' || file.extension === 'link') {
      const embargoOptions = [actionsOptions.viewFile];

      return !embargoOptions.includes(option);
    }

    if (isMultisigActivated && !isMultisigPartisipant) {
      const embargoOptions = EMBARGO_MULTISIG_OPTIONS;

      return !embargoOptions.includes(option);
    }

    return true;
  };
  const isBetaEnviroment = useMemo(
    () => process.env.REACT_APP_ENVIROMENT === 'BETA',
    []
  );
  const contextOptions = () => {
    switch (true) {
      case trash:
        return [
          actionsOptions.restoreFromTrash,
          actionsOptions.removeFromTrash,
        ].filter(contextFilter);
      case isGuestAccount:
        return [actionsOptions.viewFile, actionsOptions.download].filter(
          contextFilter
        );
      case isPublic:
        return [actionsOptions.viewFile, actionsOptions.download];
      case isMember(user):
        return [
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          isUserHasThePublicAddres ? actionsOptions.nft : null,
          !isBetaEnviroment ? actionsOptions.safetyNet : null,
          showSharing
            ? sharePermissions === 'edit'
              ? actionsOptions.removeShared
              : actionsOptions.removeFromShared
            : isDocument,
        ].filter(contextFilter);
      case isImageType:
        return [
          file.is_clientside_encrypted || isMinted
            ? null
            : actionsOptions.encrypt,
          actionsOptions.convert,
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          file.is_clientside_encrypted ? null : actionsOptions.aiGenerator,
          file.is_clientside_encrypted ? null : actionsOptions.aiUpscaller,
          isUserHasThePublicAddres ? actionsOptions.nft : null,
          actionsOptions.geoSecurity,
          !isBetaEnviroment ? actionsOptions.guardians : null,
          showSharing
            ? sharePermissions === 'edit'
              ? actionsOptions.removeShared
              : actionsOptions.removeFromShared
            : null,
        ].filter(contextFilter);

      default:
        return [
          file.is_clientside_encrypted || isMinted
            ? null
            : actionsOptions.encrypt,
          actionsOptions.convert,
          actionsOptions.myAI,
          actionsOptions.showMove,
          actionsOptions.edit,
          isUserHasThePublicAddres ? actionsOptions.nft : null,
          actionsOptions.geoSecurity,
          !isBetaEnviroment ? actionsOptions.guardians : null,
          showSharing
            ? sharePermissions === 'edit'
              ? actionsOptions.removeShared
              : actionsOptions.removeFromShared
            : null,
        ].filter(contextFilter);
    }
  };

  const renderContext = showContext ? (
    <Portal>
      <ContextMenu
        onClickOutside={onClickOutsideContext}
        contextPosition={contextPosition}
        actionHandler={actionHandler}
        options={contextOptions()}
        permission={showSharing ? sharePermissions : false}
        isPublic={isPublic}
        entity={file}
      />
    </Portal>
  ) : null;

  const renderEditForm = () => {
    return (
      <CreateEntityModal
        closeModal={onCancelEditHandler}
        modalIsOpen={isEdit}
        onCreate={onEndEditHandler}
        title={contextT('files.rename.renameFile')}
        contentLabel={contextT('files.rename.renameFile')}
        inputPlaceholder={contextT('files.rename.enterName')}
        defaultValue={name}
        acceptButtonText={contextT('files.rename.rename')}
        name={contextT('files.rename.fileName')}
      />
    );
  };

  const renderInfo = () => {
    return (
      <div className="file__text">
        {(!showSharing || showSize) && (
          <div className="file__desc text--11 text">{fileSize}</div>
        )}
        <div className="file__title text--14 text--medium">
          {renderFileName()}
        </div>
        <div className="file__date text--11 text">
          {file.created_at
            ? `${ownerT('common.created')}: ` +
              (created.isSame(moment(), 'day')
                ? commonT('common.today')
                : created.format('MMM DD, YYYY, H:mma'))
            : ''}
        </div>
      </div>
    );
  };

  const renderIcon = (color) => {
    // if (isMinted) {
    //   return <CustomFileIcon extension="nft" color={color?.hex} />;
    // }
    return (
      <CustomFileIcon
        extension={file.extension}
        color={color?.hex}
        dateCreated={file.created_at}
        onPlayClick={onFileDoubleClickHandler}
      />
    );
  };

  const onClickHandler = useClickHanler(
    onFileDoubleClickHandler,
    onFileClickHandler
  );

  const renderSmallRow = () => {
    const imageHasPreview =
      !file?.securities?.length && imageMediaTypesPreview.includes(file.mime);
    const color = file.color?.[file.color.length - 1];

    const rowFileClasses = cn({
      file: true,
      file_scaled: isDragged,
      file_small_row: true,
      showed: showFile,
      animated: true,
      fadeIn: true,
      disabled: disabled,
      active: isSelected,
      'is-edit': isEdit,
      'selected-entity': isSelectedEntity,
    });

    const iconClassNames = cn(
      {
        icon__img: true,
        'icon__img--file': true,
      },
      color?.name
    );

    const created = file?.created_at ? moment.unix(file.created_at) : moment();

    return (
      <section
        role="button"
        tabIndex={0}
        ref={forwardedRef}
        className={`${rowFileClasses} ${isRightBarOpenClassName} ${style.rowContainer}`}
        onClick={onClickHandler}
        onContextMenu={onRightFileClickHandler}
        data-test={`file-container_small-row_section[${file.slug}]`}
      >
        {viewType === 'square' && (
          <div className="folder_square_dots">
            <div className="folder_square_dots__item" />
            <div className="folder_square_dots__item" />
            <div className="folder_square_dots__item" />
          </div>
        )}
        <div className="file__container">
          {canSelect && !isMultisigActivated && (
            <Checkbox
              className="select-item-checkbox"
              onClick={onFileSelectHandler}
              checked={isSelected}
            />
          )}
          <div className={`${iconClassNames} ${style.smallCustomIcon}`}>
            {imageHasPreview && filePreview ? (
              <img
                key={file.id}
                className="file__image"
                alt={file.name}
                loading="eager"
                src={filePreview}
              />
            ) : (
              <CustomFileSmallIcon
                type={file.extension}
                onPlayClick={onFileDoubleClickHandler}
              />
            )}
          </div>
        </div>
        <div className={cn('file__text', style.textWrapper)}>
          <span className={style.smallRowText}>{file?.name}</span>
          <div className={style.iconsWrapper}>
            {file?.is_clientside_encrypted && (
              <div className={style.encryptIcon}>
                <EncryptedIcon color={'var(--iconColor)'} />
              </div>
            )}
            {color && (
              <div
                className={style.colorWrapper}
                style={{ backgroundColor: color.hex }}
              ></div>
            )}
          </div>
        </div>

        <div className="file__created">
          {created.format('MMM DD, YYYY, H:mma')}
        </div>
        {!isMobile ? (
          <div className="file__shared">
            {file?.shares?.length
              ? `${file.shares.length} ${ownerT('common.people')}`
              : '-'}
          </div>
        ) : (
          <div className="file__shared">
            {file.shares.length} <SharedUserIcon />
          </div>
        )}
        <div className="file__secured">
          {file?.securities?.length > 0
            ? file?.securities
                .map((security) => security.keyword.toUpperCase())
                .join(', ')
            : ownerT('encryptModal.no')}
        </div>

        {showType && (
          <div className="file__type text--10">
            {ownerT('rightFileMenu.meta.file')}
          </div>
        )}
        <div className="file__size">{fileSize}</div>
        {isEdit && renderEditForm()}
        {!isEdit && renderActions()}
        {renderContext}
      </section>
    );
  };
  const renderGeneral = () => {
    const imageHasPreview =
      !file?.securities?.length &&
      (imageMediaTypesPreview.includes(file.mime) ||
        imageFileExtensions.includes(`.${file.extension}`) ||
        docMediaTypesPreview.includes(file.mime) ||
        videoMediaExtentionPreview.includes(file?.extension?.toLowerCase()));
    const color = file?.color?.[file.color.length - 1];
    const videoHasPreview = videoMediaExtentionPreview.includes(
      file?.extension?.toLowerCase()
    );

    const rowFileClasses = cn({
      file: true,
      file_scaled: isDragged,
      file_square: viewType === 'square',
      'file_square-preview': viewType === 'square' && imageHasPreview,
      file_row: viewType === 'row',
      'file_row-preview': viewType === 'row' && imageHasPreview,
      showed: showFile,
      animated: true,
      fadeIn: true,
      disabled: disabled,
      active: isSelected,
      'is-edit': isEdit,
      'selected-entity': isSelectedEntity,
      'user-popup-position-container': true,
    });

    const handle360MouseMove = (e) => {
      if (!is360Panorama) return;
      const allowZooming = Date.now() - hoverStartTimeRef.current > 550;
      if (allowZooming) {
        const containerRect = e.currentTarget.getBoundingClientRect();
        const xPercent =
          ((e.clientX - containerRect.left) / containerRect.width) * 10;
        const yPercent =
          ((e.clientY - containerRect.top) / containerRect.height) * 10;
        setFloatedPreviewPosition({ x: xPercent - 5, y: yPercent - 0 });
      }
    };
    const handle360MouseLeave = () => {
      if (is360Panorama) {
        setFloatedPreview(false);
        setFloatedPreviewPosition({ x: 0, y: 0 });
      }
    };
    const handle360MouseEnter = () => {
      if (is360Panorama) {
        hoverStartTimeRef.current = Date.now();
        setFloatedPreview(true);
      }
    };

    const image = is360Panorama ? (
      <div className={style.fileContainerPreview}>
        <img
          key={file.id}
          className={cn(
            'file__image',
            is360Panorama && style.panoramaPreview,
            is360Panorama && isFloatedPreview && style.panoramaFloatedPreview
          )}
          style={
            isFloatedPreview
              ? {
                  transform: `translate(${floatedPreviewPosition.x}%, ${floatedPreviewPosition.y}%)`,
                }
              : {}
          }
          alt={file.name}
          loading="eager"
          src={filePreview}
        />
      </div>
    ) : (
      <img
        key={file.id}
        className={'file__image'}
        alt={file.name}
        loading="eager"
        src={filePreview}
      />
    );

    const content = (
      <section
        role="button"
        ref={forwardedRef}
        tabIndex={0}
        className={rowFileClasses}
        onClick={onClickHandler}
        onContextMenu={onRightFileClickHandler}
        data-test={`file-comtainer_content_section[${file.slug}]`}
        onMouseEnter={handle360MouseEnter}
        onMouseLeave={handle360MouseLeave}
        onMouseMove={handle360MouseMove}
      >
        {viewType === 'square' && (
          <div onClick={onRightFileClickHandler} className="folder_square_dots">
            <div className="folder_square_dots__item" />
            <div className="folder_square_dots__item" />
            <div className="folder_square_dots__item" />
          </div>
        )}
        <div
          className={cn(
            'file__container',
            imageHasPreview && filePreview && 'file__container-preview'
          )}
        >
          {canSelect && !isMultisigActivated && (
            <Checkbox
              inputStyle={
                imageHasPreview && viewType === 'square'
                  ? checkboxStyle.DARK
                  : checkboxStyle.LIGHT
              }
              className={cn('select-item-checkbox', style.checkbox)}
              onClick={onFileSelectHandler}
              checked={isSelected}
              data-test="file-container_square_file-select_checkbox"
            />
          )}
          {filePreview ? (
            <>
              {image}
              {videoHasPreview && (
                <>
                  <PlayIcon
                    className={style.videoPlayIcon}
                    onClick={onFileDoubleClickHandler}
                    onMouseEnter={openHint}
                    onMouseLeave={closeHint}
                  />
                  {showHint && <CustomIconHint position={'top'} />}
                </>
              )}
            </>
          ) : (
            renderIcon(color)
          )}
        </div>
        {renderInfo()}
        {isEdit && renderEditForm()}
        {!isEdit && renderActions()}
        {renderContext}
        {(viewType === 'square' || viewType === 'row') && (
          <div className="file__secured-shared-container">
            {isShared && (
              <div className="file__secured-shared">
                <SharedIcon />
              </div>
            )}
            {isMinted && (
              <div className="file__secured-shared">
                <DiamondIcon
                  width={17}
                  height={18}
                  viewBoxWidth={22}
                  viewBoxHeight={20}
                />
              </div>
            )}
            {file?.is_clientside_encrypted && (
              <div className="file__secured-shared">
                <EncryptedIcon />
              </div>
            )}
            {file?.securities?.length > 0 && (
              <div className="file__secured-shared">
                <SecuredIcon />
              </div>
            )}

            {viewType === 'row' && (
              <Users
                users={file.shares?.filter((share) => share.type === 3)}
                color={color}
                userPopupRef={fileItemRef}
                maxUsersToShow={3}
              />
            )}
          </div>
        )}
        <div className="user-popup-portal" ref={fileItemRef} />
      </section>
    );

    return viewType === 'square' ? (
      <div className="square__item">{content}</div>
    ) : (
      content
    );
  };

  const content = viewType === 'small-row' ? renderSmallRow() : renderGeneral();
  const wrappedContent = connectDragSource
    ? connectDragSource(content)
    : content;

  return (
    <>
      {wrappedContent}
      <AnalyticsModal
        closeModal={() => {
          setAnalyticsIsOpen(false);
        }}
        isOpen={analyticsIsOpen}
        slug={file.slug}
      />
      {isAllowManagePopupOpen && (
        <AllowManageKeyPopup
          file={file}
          onClose={() => setIsAllowManagePopupOpen(false)}
          goToTokenization={() => {
            fileFolderActionHandlerEffect(
              file,
              actionsOptions.goToTokenization
            );
            setIsAllowManagePopupOpen(false);
          }}
          isOpen={isAllowManagePopupOpen}
        />
      )}
    </>
  );
};

FileContainer.propTypes = {
  file: PropTypes.object,
  options: PropTypes.array.isRequired,
  showSharing: PropTypes.bool,
  showSize: PropTypes.bool,
  connectDragSource: PropTypes.func,
  isDragged: PropTypes.bool.isRequired,
  isDocument: PropTypes.bool,
  canDrag: PropTypes.bool,
  isEdit: PropTypes.bool,
  onDragBegin: PropTypes.func,
  onDragEnd: PropTypes.func,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  canSelect: PropTypes.bool,
  hideActions: PropTypes.bool,
  disableClick: PropTypes.bool,
  disableDoubleClick: PropTypes.bool,
  doAfterEdit: PropTypes.func,
  fileFolderActionHandlerEffect: PropTypes.func,
  selectedFilesAddEffect: PropTypes.func,
  selectedFilesRemoveEffect: PropTypes.func,
  onCheckChange: PropTypes.func,
  trash: PropTypes.bool,
  optionHandler: PropTypes.func,
};

FileContainer.defaultProps = {
  isEdit: false,
  canDrag: false,
  showSharing: false,
  isDocument: false,
  showSize: false,
  disabled: false,
  canSelect: true,
  showType: false,
  hideActions: false,
  disableClick: false,
  disableDoubleClick: false,
  doAfterEdit: () => {},
  fileFolderActionHandlerEffect: () => {},
  selectedFilesAddEffect: () => {},
  selectedFilesRemoveEffect: () => {},
  optionHandler: () => {},
  onCheckChange: () => {},
};

function isEqual(prev, next) {
  return (
    prev.selectedEntity.entity?.slug === next.selectedEntity.entity?.slug &&
    prev.viewType === next.viewType &&
    prev.isSelected === next.isSelected &&
    prev.isEdit === next.isEdit &&
    prev.file?.is_clientside_encrypted === next.file?.is_clientside_encrypted &&
    prev.file?.thumbnail === next.file?.thumbnail &&
    prev.file?.entry_clientside_key?.tag ===
      next.file?.entry_clientside_key?.tag &&
    prev.file?.entry_clientside_key?.iv ===
      next.file?.entry_clientside_key?.iv &&
    prev.file.color?.[prev.file.color.length - 1] ===
      next.file.color?.[next.file.color.length - 1] &&
    prev.file?.entry_groups === next.file?.entry_groups
  );
}

export default DragSource(
  dndTypes.FILE,
  FileSource,
  collectDragSource
)(React.memo(FileContainer, isEqual));
