/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { isMobile } from 'utils/mobile';

import NeyraBorder from 'components/NeyraBorder';
// import NeyraSmallLogo from 'components/NeyraSmallLogo';

import { ReactComponent as HeartIcon } from './assets/ecg_heart.svg';
import { ReactComponent as BluetoothIcon } from './assets/bluetooth.svg';
import { ReactComponent as BatteryIcon } from './assets/battery_change.svg';
import { ReactComponent as ThermometerIcon } from './assets/thermometer_gain.svg';
import { ReactComponent as CognitionIcon } from './assets/cognition.svg';
import RingVideo from 'static/videos/NeyraRingVideo.webm';

import { getRingInfo } from 'store/neyra/effects';

// import RingImage from './assets/ring_image.webp';
// import RingImageTab from './assets/ring_image_tab.webp';

import s from './style.module.scss';

const actionsIcons = [
  HeartIcon,
  ThermometerIcon,
  BluetoothIcon,
  BatteryIcon,
  CognitionIcon,
];

const getRingAdvantages = (t) => {
  return [
    {
      title: t('ring.awareness'),
      desc: t('ring.awarenessText'),
    },
    {
      title: t('ring.emotional'),
      desc: t('ring.emotionalText'),
    },
    {
      title: t('ring.expression'),
      desc: t('ring.expressionText'),
    },
    {
      title: t('ring.challenges'),
      desc: t('ring.challengesText'),
    },
  ];
};

export const NeyraRing = () => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const isTablet = isMobile && window.innerWidth <= 860;
  const isSmallMobile = isMobile && window.innerWidth <= 480;
  const ringAdvantages = getRingAdvantages(t);

  useEffect(() => {
    const initiate = async () => {
      try {
        const data = await getRingInfo();
      } catch (error) {
        console.log(error);
      }
    };
    initiate();
  }, []);

  const handleOrder = () => {
    history.push('/order-ring');
  };

  const sidebar = isSmallMobile ? (
    <div className={s.buttonsWrapper}>
      <button className={s.orderRing} onClick={handleOrder}>
        {t('ring.button')}
        <span>{t('ring.preorderStart')}</span>
      </button>
    </div>
  ) : (
    <div className={s.sidebar}>
      {!isTablet && (
        <div className={s.sidebar__title}>
          <h2>{t('ring.title')}</h2>
          <p>{t('ring.subTitle')}</p>
        </div>
      )}
      <ul className={s.listWrapper}>
        {ringAdvantages.map((el) => (
          <li key={Math.random()}>
            <h2>{el.title}</h2>
            <p>{el.desc}</p>
          </li>
        ))}
      </ul>
      {!isTablet && (
        <div className={s.actionsWrapper}>
          {actionsIcons.map((Icon) => (
            <Icon key={Math.random()} />
          ))}
        </div>
      )}
      <div className={s.buttonsWrapper}>
        <button className={s.orderRing} onClick={handleOrder}>
          {t('ring.button')}
          <span>$199</span>
        </button>
      </div>
    </div>
  );

  const renderSidebar = isTablet ? sidebar : <></>;

  const mobileDescription = (
    <div className={s.mobileDescription}>
      <div className={s.title}>
        <h2>{t('ring.title')}</h2>
        <p>{t('ring.subTitle')}</p>
      </div>

      <ul className={s.listWrapper}>
        {ringAdvantages.map((el) => (
          <li key={Math.random()}>
            <h2>{el.title}</h2>
            <p>{el.desc}</p>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className={s.ringWrapper}>
      <NeyraBorder
        isBorder={true}
        containerClass={s.containerClass}
        contentClass={s.contentClass}
        sidebar={renderSidebar}
      >
        <div className={s.ringImageWrapper}>
          <video
            width="auto"
            height="100%"
            autoPlay
            muted
            onEnded={(e) => {
              e.currentTarget.play();
            }}
          >
            <source src={RingVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </NeyraBorder>
      {!isTablet && sidebar}
      {isSmallMobile && mobileDescription}
    </div>
  );
};
