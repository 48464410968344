import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router';

import NeyraBorder from 'components/NeyraBorder';
import { ReactComponent as NeyraIcon } from 'components/NeyraBorder/assets/neyra.svg';
import { ReactComponent as BigCircleIcon } from 'containers/auth/NeyraBios/assets/big-circle.svg';
import { ReactComponent as ProgressRing } from 'containers/auth/NeyraBios/assets/progress-ring.svg';
import NotificationBubble from 'containers/notifications/notification-bubble';
import { MainContextWrapper } from 'store/context';

import { getAvatarBySlug } from 'store/neyra/effects';
import { NEIRA_AI_API_BASE } from 'constants/api-urls';

import styles from 'containers/auth/NeyraBios/components/AuthContent/styles.module.scss'
import useNotification from 'utils/hooks/use-notification';

const Avatar = () => {
  const { addNotification } = useNotification();
  const [avatar, setAvatar] = useState({});
  const history = useHistory();

  useEffect(async () => {
    try {
      const avatarSlug = history.location.pathname.split('/')[1];
      const data =  await getAvatarBySlug(avatarSlug);
      if (data?.id) {
        setAvatar({
          ...data.profile,
          id: data.id
        })
      } else {
        addNotification('Avatar not found', 'alert')
      }
    } catch (error) {
      addNotification('Avatar not found', 'alert')
      console.warn(error.message)
    }
  }, [])

  const renderNavigate = (list) => {
    return list.map((item, index) => {
      if (index === 0) {
        return (
          {
            action: () => {
              if (avatar.id) {
                history.push(`/chat?avatar=${avatar?.slug}`)
              } else {
                history.push('/chat')
              }
            },
            icon: <NeyraIcon />,
            name: 'chat',
            ref: ''
          }
        )
      } else {
        return item
      }
    })
  }

  return (
    <NeyraBorder
      initiateNeyraUser={false}
      renderNavigate={renderNavigate}
      showBorder
    >
      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <BigCircleIcon className={styles.bigCircle} />
          <ProgressRing  className={styles.progressRing} />
          {avatar?.image && (
            <div className={styles.addLogoTP}>
              <img
                src={ avatar?.image ? `${NEIRA_AI_API_BASE}${avatar?.image}` : '' }
                alt="avatar"
                className={styles.logoPreview}
              />
            </div>
          )}
        </div>
        <div className={styles.typer}>
          <p>{avatar.name}</p>
        </div>
      </div>
      <NotificationBubble/>
    </NeyraBorder>
  )
}

const AvatarContainer = (props) => {
  return (
    <MainContextWrapper>
      <Avatar {...props} />
    </MainContextWrapper>
  )
}

export default AvatarContainer