/* eslint-disable no-debugger */
// import removeToken from 'utils/auth/remove-token';
import * as actions from '../../actions/get-user-info.actions';
// import * as accountActions from '../../../account/actions';
import { changeUserInfo } from 'store/account/actions';
import { workspacePlanAdd, workspaceAddCurrent } from 'store/plan/actions';
import { setMultisigUsers } from 'features/workspaceSettings/workspaceSettingsSlice';
import { setTokenCount } from 'features/app';
import { isMultisigParticipant } from 'store/web3/multisig';
import authRequest from 'utils/request/auth-request';
import { API_USER_INFO } from 'constants/api-urls';
import { isMultisigActivated } from 'utils/roles';
import removeToken from 'utils/auth/remove-token';
// import { authTokenRefresh } from 'store/auth/effects';

export const getUserInfoEffect = (errCb, successCb) => async (dispatch) => {
  dispatch(actions.getUser());
  const url = API_USER_INFO;

  await authRequest
    .get(url)
    .then((result) => {
      const smartContracts = result.data.current_workspace.smart_contracts;
      const account = Object.assign({}, result.data.user);
      const isMultisigActive = isMultisigActivated(smartContracts);
      dispatch(changeUserInfo(account));
      dispatch(actions.getUserSuccess(account));
      dispatch(setTokenCount(result.data.tokens));
      dispatch(actions.getUserFreeActions(result.data.free_actions));
      dispatch(setMultisigUsers(result.data.multisig_user));
      dispatch(workspaceAddCurrent(result.data.current_workspace));
      dispatch(workspacePlanAdd(result.data.workspace_plan));
      isMultisigActive &&
        isMultisigParticipant(result.data.multisig_user, account, dispatch);
      successCb && successCb();
    })
    .catch(({ message, response }) => {
      if (response?.status === 401) {
        // authTokenRefresh().then(() => {

        //   // window.location.reload();
        // })
        // .catch(() => {
        removeToken();
        //   window.location.href = '/';
        // });
      }

      dispatch(actions.getUserFail(message));
      errCb && errCb();
    });
};

export const getUserInfoClearEffect = () => async (dispatch) => {
  try {
    const data = await authRequest.get(API_USER_INFO);
    dispatch(changeUserInfo(data.data.user));
    dispatch(setTokenCount(data.data.tokens));
  } catch (erro) {
    console.warn(erro);
  }
};

export const getUserInfoClear = async () => {
  try {
    const data = await authRequest.get(API_USER_INFO);
    return data.data;
  } catch (erro) {
    console.warn(erro);
  }
};
